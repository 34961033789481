import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimulationInformationModel, SimulationModel } from 'src/app/shared/models/simulation.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  constructor(private http: HttpClient) {}

  saveSimulation(model: SimulationModel){
    return this.http.post<SimulationModel>(`${environment.apiUrls.simulation}`, model)
  }

  decriptParam(param: string): Observable<any> {
    param = param.replace(/\//g, "_");
    param = this.replaceChars(param, " ", "+");
    console.log(param);
    return this.http.get<any>(`${environment.apiUrls.decriptParam}/${param}`)
  }

  public getPercentageCampaign24(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrls.simulation}/configuration/campaign-24`);
  }

  replaceChars(stringToReplace, findChar: string, replaceChar) {
    let index = stringToReplace.indexOf(findChar);
    while(index > -1) {
      stringToReplace = stringToReplace.replace(findChar, replaceChar);
      index = stringToReplace.indexOf(findChar);
    }
    return stringToReplace;
  }

  saveSimulationInformation(model: SimulationInformationModel): Observable<any> {
    return this.http.post<any>(`${environment.apiUrls.simulationInformation}`, model);
  }

}
