/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ATENÇÃO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Não esquecer de equalizar todas as URL's nos demais arquivos de enviromment.
 *
 */
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import 'zone.js/dist/zone';
import * as ApiBaseUat from './api-base/api-base-uat';
import * as simuladorApi from './switchContext';

export const apiBase = `${ApiBaseUat.apiBase}/bwa`;
export const context = simuladorApi.simuladorApi ? 'simulador-api' : 'credenciamento/online';
export const environment = {
  firstOffer10k30k: {
    campaignId: 558,
    feeType: FeeTypeEnum.FLEX,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 120.000,01',
    billingRangeEnd: 'R$ 360.000,00',
    monthBillingStart: 'R$ 10.000,00',
    monthBillingEnd: 'R$ 30.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 59,00 / Pinpad Serial ou USB Aluguel: R$ 49,00 ',
    label: '',
    value: '',
  },
  secondOffer10k30k: {
    campaignId: 562,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 120.000,01',
    billingRangeEnd: 'R$ 360.000,00',
    monthBillingStart: 'R$ 10.000,00',
    monthBillingEnd: 'R$ 30.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 59,00 / Pinpad Serial ou USB Aluguel: R$ 49,00 ',
    label: '',
    value: '',
  },
  firstOffer30k85k: {
    campaignId: 559,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 360.000,01',
    billingRangeEnd: 'R$ 1.020.000,00',
    monthBillingStart: 'R$ 30.000,00',
    monthBillingEnd: 'R$ 85.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 49,00 / Pinpad Serial ou USB Aluguel: R$ 39,00',
    label: '',
    value: '',
  },
  secondOffer30k85k: {
    campaignId: 563,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 360.000,01',
    billingRangeEnd: 'R$ 1.020.000,00',
    monthBillingStart: 'R$ 30.000,00',
    monthBillingEnd: 'R$ 85.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 49,00 / Pinpad Serial ou USB Aluguel: R$ 39,00',
    label: '',
    value: '',
  },
  firstOffer85k250k: {
    campaignId: 560,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 1.020.000,01',
    billingRangeEnd: 'R$ 3.000.000,00',
    monthBillingStart: 'R$ 85.000,00',
    monthBillingEnd: 'R$ 250.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 39,00 / Pinpad Serial ou USB Aluguel: R$ 29,00',
    label: '',
    value: '',
  },
  secondOffer85k250k: {
    campaignId: 564,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 1.020.000,01',
    billingRangeEnd: 'R$ 3.000.000,00',
    monthBillingStart: 'R$ 85.000,00',
    monthBillingEnd: 'R$ 250.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 39,00 / Pinpad Serial ou USB Aluguel: R$ 29,00',
    label: '',
    value: '',
  },
  firstOfferPlus250k: {
    campaignId: 561,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  secondOfferPlus250k: {
    campaignId: 565,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  production: true,
  development: false,
  apiGee: {
    apiKey: 'X0GW3QGOYFn4r7DHcVC8KuatUnNs6MGB',
    apiKeySecret: 'N93nmFmoY0NzSMC5',
    clientSecretId: 'FA9zeMHYGyX0dG3',
  },
  saveSensitiveDataOnLocalStorage: false,
  ADMIN_URL: ApiBaseUat.adminUrl,
  apiUrls: {
    simulation: `${apiBase}/${context}/simulator/simulation`,
    simulationConfig: `${apiBase}/${context}/simulator-config`,
    cnaeSimulator: `${apiBase}/${context}/simulator/cnae`,
    mccSimulator: `${apiBase}/${context}/mcc`,
    reportSimulator: `${apiBase}/${context}/simulator/report`,
    feesSimulator: `${apiBase}/${context}/simulator/rates`,
    labels: `${apiBase}/${context}/simulator/report-labels`,
    negociation: `${apiBase}/${context}/simulator/send-email`,
    decriptParams: `${apiBase}/${context}/simulator/decrypt-params`,
    decriptParam: `${apiBase}/${context}/simulator/decrypt-param`,
    campaigns: `${apiBase}/${context}/simulator/campaigns`,
    economicGroup: `${apiBase}/${context}/simulator/economic-group`,
    economicalGroupConvenience: `${apiBase}/${context}/simulator/economic-group-conv`,
    progressiveDiscount: `${apiBase}/${context}/simulator/progressive-discount`,
    reportProgressiveDiscount: `${apiBase}/${context}/simulator/progressive-discount/report`,
    reportCustomerProgressiveDiscount: `${apiBase}/${context}/simulator/customer-progressive-discount/report`,
    comercialConditions: `${apiBase}/${context}/commercial-conditions`,
    economicGroupSimulation: `${apiBase}/${context}/economic-group-simulation`,
    economicGroupCreation:`${apiBase}/${context}/economic-group`,
    versionApi: `${apiBase}/${context}/isAlive`,
    simulationInformation: `${apiBase}/${context}/simulator/client-information`
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone relEated error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 */
