import { Injectable, type Type as Component } from '@angular/core';
import { SidenavContentAreaDirective } from './side-nav-content-area.directive';

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    private contentArea?: SidenavContentAreaDirective;


    setDynamicContentArea(host: SidenavContentAreaDirective) {
        this.contentArea = host;
    }

    push(component: Component<unknown>): void {
        this.setContent(component);
    }

    setContent(component: Component<unknown>): void {
        this.contentArea?.viewContainerRef.clear();
    
        this.contentArea?.viewContainerRef.createComponent(component);
    }
}