import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinishComponent } from './order/components/simulation-data/finish/finish.component';
import { OrderStepGuard } from './order/guards/step-guard';
import { SimulationComponent } from './order/simulation.component';
import { SimulationEconomicalGroup } from './order/components/simulation-economical-group/simulation-economical-group.component';
import { GroupPricingComponent } from './order/components/group-pricing/group-pricing.component';
import { SimulationSearch } from './order/components/simulation-search/simulation-search.component';
import { CnaeAndMccSearch } from './order/components/cnae-and-mcc-search/cnae-and-mcc-search.component';


const routes: Routes = [
  {
    path: 'simulation/client-information',
    canActivate: [OrderStepGuard],
    component: SimulationComponent
  },
  {
    path: 'simulation/commercial-group',
    canActivate: [OrderStepGuard],
    component: SimulationEconomicalGroup
  },
  {
    path: 'simulation/group-pricing',
    canActivate: [OrderStepGuard],
    component: GroupPricingComponent
  },
  {
    path: 'simulation/simulation-search',
    canActivate: [OrderStepGuard],
    component: SimulationSearch
  },
  {
    path: 'simulation/cnae-and-mcc-search',
    canActivate: [OrderStepGuard],
    component: CnaeAndMccSearch
  },
  {
    path: 'simulation/finish',
    component: FinishComponent
  },
  {
    path: 'negotiation/finish',
    component: FinishComponent
  },
  {
    path: '**',
    component: SimulationComponent,
    canActivate: [OrderStepGuard],
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


