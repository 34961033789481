const digitRegExp = /\d/;

export function OnlyNumberMask(value) {
  const arr = value.split('').map(char => {
    if (digitRegExp.test(char) && value > 0) {
      return digitRegExp;
    }
  });

  return arr;
}
