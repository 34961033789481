import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavContentAreaDirective } from './side-nav-content-area.directive';
import { SidenavService } from './side-nav.service';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @ViewChild(SidenavContentAreaDirective, { static: true })
  sidenavContentArea?: SidenavContentAreaDirective;

  constructor(public sidenavService: SidenavService) { }

  async ngOnInit() {
    if (!this.sidenavContentArea) {
      console.log('sidenavContentArea is undefined');
    }

    this.sidenavService.setDynamicContentArea(this.sidenavContentArea);
  }

}
