<div class="fd-accreditation-form__container">
  <div class="forms" fxLayout="column" fxLayoutAlign="center stretch">
  <h4 class="fd-subtitle">SIMULADOR DE TAXAS GRUPO COMERCIAL AZULZINHA</h4>
  <app-economical-group-form
    *ngIf="viewControl.clientInformation"
    [clientInformation]="proposal.clientInformation"
    (consultOffers)="showFeesData($event)"
    (cleanDown)="clear()"
    >
  </app-economical-group-form>

  <h4 class="fd-subtitle" *ngIf="!isEmptyPrePayment()">Taxa de antecipação automática</h4>
  <app-prepayment-table class="center-table" *ngIf="!isEmptyPrePayment()" [data]="prepaymentData"></app-prepayment-table>

  <h4 class="fd-subtitle" *ngIf="!isEmptyRates()">Taxas MDR</h4>
  <app-simulation-economical-group-table [tableName]="'Taxas MDR'" [data]="genericMdrFee$ | async"></app-simulation-economical-group-table>

  <h4 class="fd-subtitle" *ngIf="!isEmptyTechnology()">Tecnologias Disponíveis</h4>
  <app-technology-price-table class="center-table" *ngIf="!isEmptyTechnology()" [data]="technologyData"></app-technology-price-table>

  <h4 class="fd-subtitle" *ngIf="!isEmptyExemptionPolicyFees()">Política de Isenção</h4>
  <app-exemption-policy-table class="center-table" *ngIf="!isEmptyExemptionPolicyFees()" [description]="description" [data]="exemptionPolicyFeesData"></app-exemption-policy-table>
  

</div>

<div fxLayoutAlign="center stretch" *ngIf="!isEmptyRates()">
  <div fxFlex="40">
    <fd-button size="thin"  [content]="'Voltar para o simulador de taxas'" (trigger)="initialPage()"></fd-button>
  </div>
  <div fxFlex="40">
    <fd-button
      size="thin"
      [content]="'Seguir para precificação do grupo'"
      (trigger)="newPrecification()"
    ></fd-button>
  </div>
  </div>
</div>
