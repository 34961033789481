import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faBars, faCamera, faChevronDown, faExclamationTriangle, faInfoCircle, faPencilAlt, faSearch, faSignOutAlt, faSortDown, faTools, faTrash, faUserCircle, fas } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [ FontAwesomeModule ],
  exports: [ FontAwesomeModule ]
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(faArrowLeft,
      faChevronDown,
      faCamera,
      faSearch,
      faUserCircle,
      faInfoCircle,
      faSortDown,
      faSignOutAlt,
      faBars,
      faExclamationTriangle,
      faPencilAlt,
      faTrash,
      faTools);
  }
}