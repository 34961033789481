import { MdrFeeModel } from "src/app/shared/models/response/response-mdr-fee.model";
import { MdrFlexFeeModel } from "src/app/shared/models/response/response-mdr-flex-fee.model";
import { Phrases } from "../../fees-data/models/param";
import { FeeDTO } from "../../../simulation-economical-group/models/economical-group-simulation.model";

export class GenericMdrFeeModel {
    codeFlag: number;
    flag: string;
    serviceId: number;
    minimumMdrFee: number;
    suggestedMdrFee: number | string;
    transaction: string;
    modality: string;
    phrases: Phrases;
    type: string;
    suggestedMdrFeeTariff: number | string;

    public readonly TAX = 'TAX';
    public readonly CURRENCY = 'CURRENCY';

    constructor(mdrFeeModel: MdrFeeModel | MdrFlexFeeModel | FeeDTO, hasDebitPercentageCampaign24: boolean) {
        this.codeFlag = (mdrFeeModel as MdrFeeModel | MdrFlexFeeModel).codeFlag;
        this.flag = (mdrFeeModel as MdrFeeModel | MdrFlexFeeModel).flag || (mdrFeeModel as FeeDTO).brand;
        this.modality = mdrFeeModel.modality;
        this.serviceId = (mdrFeeModel as MdrFeeModel | MdrFlexFeeModel).serviceId;
        this.transaction = (mdrFeeModel as MdrFeeModel | MdrFlexFeeModel).transaction;
        this.minimumMdrFee = (mdrFeeModel as MdrFeeModel).minimumMdrFee || (mdrFeeModel as MdrFlexFeeModel).minimumMdrFlexFee;
        this.type = (mdrFeeModel as MdrFeeModel | MdrFlexFeeModel).type;
        if(!hasDebitPercentageCampaign24) {
            if(this.isTax((mdrFeeModel as MdrFeeModel | MdrFlexFeeModel).type)){
                this.suggestedMdrFeeTariff = "---"
                this.suggestedMdrFee = (mdrFeeModel as MdrFeeModel).suggestedMdrFee || (mdrFeeModel as MdrFlexFeeModel).suggestedMdrFlexFee || (mdrFeeModel as FeeDTO).fee;
                this.suggestedMdrFee = this.suggestedMdrFee == undefined? '--' : this.suggestedMdrFee;
            }else if(this.isCurrency((mdrFeeModel as MdrFeeModel | MdrFlexFeeModel).type)){
                this.suggestedMdrFee =  "---"
                this.suggestedMdrFeeTariff = (mdrFeeModel as MdrFeeModel).suggestedMdrFee || (mdrFeeModel as MdrFlexFeeModel).suggestedMdrFlexFee;
            }
        } else {
          this.suggestedMdrFee = (mdrFeeModel as MdrFeeModel).suggestedMdrFee || (mdrFeeModel as MdrFlexFeeModel).suggestedMdrFlexFee;
          this.suggestedMdrFee = this.suggestedMdrFee == undefined? '--' : this.suggestedMdrFee;
        }

    }

    private isTax(type: string){
        return type === this.TAX;
    }

    private isCurrency(type: string){
        return type === this.CURRENCY;
    }

    public static arrayMap(mdrFeeModelsArray: any[], hasDebitPercentageCampaign24: boolean): GenericMdrFeeModel[] {
        if (mdrFeeModelsArray instanceof MdrFeeModel || MdrFlexFeeModel) {
           return mdrFeeModelsArray.map(mdrFeeModel => new GenericMdrFeeModel(mdrFeeModel, hasDebitPercentageCampaign24));
        }
    }


}
