<div class="fd-business-data-detail__card-container width35" *ngIf="data">
  <table mat-table [dataSource]="dataSource" class="fd-table" aria-label="Table containing technology rent price">
    <!-- ID Column -->
    <ng-container matColumnDef="tipoTerminalDesc" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Tecnologia</th>
      <td mat-cell *matCellDef="let rowElement">{{ rowElement.tipoTerminalDesc }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="valor" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Valor Mensal</th>
      <td class="align-center" mat-cell *matCellDef="let rowElement">{{ rowElement.valor ? (rowElement.valor | currency: 'BRL') : "-" }}</td>
    </ng-container>

    <!-- Color Column -->
    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
