import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { version } from '../environments/version';
import { SidenavService } from './shared/side-nav/side-nav.service';
import { DefaultSideNavComponent } from './shared/side-nav/side-navs/default-side-nav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(
    public sidenavService: SidenavService,
    private cdr: ChangeDetectorRef
  ) {}

  title = 'credenciamento';

  ngOnInit() {
    console.log('Last build date:', version.timeStamp);
    console.log('Version:', version.version);
  }

  ngAfterViewInit(): void {
    this.sidenavService.push(DefaultSideNavComponent);
    this.cdr.detectChanges();
  }

}
