<div *ngIf="!!dataSource">
  <h4 class="fd-subtitle">{{ tableName }}</h4>

  <table mat-table [dataSource]="dataSource" class="fd-table" aria-label="Table containing informations about the requested simulation">
    <ng-container [matColumnDef]="prop.name" *ngFor="let prop of objectProperties" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">
        {{ prop.columnTitle }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ resolveValue(row, prop) }}
      </td>
    </ng-container>
    <tr mat-header-row  *matHeaderRowDef="displayedColumns" class="fd-table__header-row" ></tr>
    <tr
      mat-row
      class="fd-table__row"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</div>
