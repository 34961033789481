
<div class="fd-accreditation-form__container">
  <div class="forms" fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">CONSULTA DE GRUPOS COMERCIAIS AZULZINHA</h4>
    <form *ngIf="!!formGroup && !!fields" [formGroup]="formGroup">
      <div fxLayout="row" fxLayoutAlign="left stretch">
        <h4 >Informe o CNPJ que deseja consultar</h4>
      </div>
      <div fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px">
        <div fxFlex="80">
          <fd-input [parentForm]="formGroup" [field]="fields.cnpj"> </fd-input>
        </div>
        <div fxFlex="20">
          <button class="custom-button"
             matTooltip ="Buscar"
            (click)="getSimulationsByFilter()">
            BUSCAR
        </button>
        </div>
        </div>
      </div>
    </form>
    </div>


  <table mat-table [dataSource]="dataSource" class="table" style="width: 100%" aria-label="Table containing information about economical groups">
    <ng-container matColumnDef="simulationCod">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID da Precificação</th>
      <td mat-cell *matCellDef="let element"> {{ element.simulationCod }} </td>
    </ng-container>

    <ng-container matColumnDef="monthlyBillingVal">
        <th mat-header-cell *matHeaderCellDef>Faturamento mensal do grupo no cartão</th>
        <td mat-cell *matCellDef="let element">{{ element.monthlyBillingVal }} </td>
      </ng-container>

      <ng-container matColumnDef="simulationDate">
        <th mat-header-cell *matHeaderCellDef>Data da Precificação</th>
        <td mat-cell *matCellDef="let element"> {{ element.simulationDate | date: 'dd/MM/yyyy' }} </td>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef>Vencimento da Precificação</th>
        <td mat-cell *matCellDef="let element"> {{ element.expirationDate | date: 'dd/MM/yyyy' }} </td>
      </ng-container>

      <ng-container matColumnDef="simulationStatusdescription">
        <th mat-header-cell *matHeaderCellDef>Status do Grupo</th>
        <td mat-cell *matCellDef="let element"> {{ element.simulationStatusdescription }} </td>
      </ng-container>

      <ng-container matColumnDef="simulationPricingDetails">
        <th mat-header-cell *matHeaderCellDef>Visualizar Precificação</th>
        <td mat-cell *matCellDef="let element">
          <fa-icon class="proposal_detail_pricing" style="font-size:1.5em" matTooltip="Precificação do Grupo" (click)="pricingDetail(element.simulationCod)" icon="eye">
          </fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="contractDownload">
        <th mat-header-cell *matHeaderCellDef>Download do Contrato</th>
        <td mat-cell *matCellDef="let element">
          <fa-icon class="proposal_detail" style="font-size:1.5em" matTooltip="Download do contrato" (click)="downloadContract(element.simulationCod)" icon="file-download">
          </fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="createEconomicGroup">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Criar grupo comercial</th>
        <td mat-cell *matCellDef="let element">
          <button
          [disabled]="element.simulationStatusdescription != 'Aguardando assinatura de contrato' "
          class="create-button"
          matTooltip="Criar Grupo"
          (click)="createGroup(element.simulationCod)"
            >
            CRIAR GRUPO
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="simulationDetails">
        <th mat-header-cell *matHeaderCellDef>Detalhes</th>
        <td mat-cell *matCellDef="let element">
          <fa-icon class="proposal_detail" style="font-size:1.5em" matTooltip="Detalhes do grupo" (click)="detail(element)" icon="file">
          </fa-icon>
        </td>
      </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator *ngIf="hasValue" #paginator
    [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
    (page)="change($event)" [length]="totalPages"></mat-paginator>
</div>
