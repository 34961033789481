<div *ngIf="commercialConditionsHistoryDTO" >

    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Precificação do Grupo" >
            <div *ngIf="commercialConditionsHistoryDTO.simulationPricingHistory.anticipationFee" fxLayout="column"  fxLayoutAlign="center">
                <div class="advanceFeePropertiesTableComponent">
                    <app-simulation-table
                        [dataSource]="advanceFee$ | async"
                        [objectProperties]="advanceFeeProperties"
                        tableName="Taxa de antecipação automática">
                    </app-simulation-table>
                </div>
            </div>

            <div fxLayout="column"  fxLayoutAlign="center">
                <div class="pricingTableComponent">
                    <app-simulation-economical-group-pricing-table
                    [data]="pricingDataSource$ | async">
                    </app-simulation-economical-group-pricing-table>
                </div>
            </div>
            <div fxLayout="row"  fxLayoutAlign="center">
                <div class="technologyTableComponent">
                    <app-simulation-table
                        [dataSource]="technologyDataSource$ | async"
                        [objectProperties]="technologyDataSourceProperties"
                        tableName="Tecnologias Disponíveis">
                    </app-simulation-table>
                </div>
            </div>

            <div fxLayout="column"  fxLayoutAlign="stretch center">
                <div class="policyFeesTableComponent">
                    <app-simulation-table
                        [dataSource]="exemptionPolicyFeesDataSource$ | async"
                        [objectProperties]="policyFeesDataSourceProperties"
                        tableName="Política de Isenção">
                    </app-simulation-table>
                    <div *ngIf="exemptionPolicyDescription">
                        <span class="fd-text">* {{exemptionPolicyDescription}}</span>
                    </div>
                </div>
            </div>

            <div fxLayoutAlign="center" style="margin-top: 10%;">
                <fd-button
                class="no-print"
                [content]="'FECHAR'"
                (trigger)="close()"
                ></fd-button>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>
