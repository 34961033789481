import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { TokenInformationModel } from 'src/app/shared/models/response/auth-service.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { Constants } from '../constants/constants';
import { DialogService } from '../services/internal/dialog/dialog.service';
import { LocalStorageAgentData, LocalStorageData, SessionInformation } from './data-store-models';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  public serivceContractBinAndSipag: number[] = [101, 102, 103];

  constructor(private dialogService: DialogService) {}

  setRegistrationUserCode(registrationUserCode: string) {
    sessionStorage.setItem(Constants.USER_DATA, registrationUserCode);
  }

   setParam(param: any) {
    const cpfCnpj = param['cnpj'];
    const state = param['uf'];
    const cnaeParam = param['cnae'];
    const registrationNumber = param['matricula'];

    sessionStorage.setItem(Constants.CNAE, cnaeParam);
    sessionStorage.setItem(Constants.UF, state);
    sessionStorage.setItem(Constants.CPF_CNPJ, cpfCnpj);
    sessionStorage.setItem(Constants.USER_DATA, registrationNumber);
  }

  getRegisterUserCode(): string {
    return sessionStorage.getItem(Constants.USER_DATA);
  }

  public getUserData(): UserDataModel {
    const sessionStorageItem = sessionStorage.getItem(Constants.ACCREDITATION_USER_DATA_KEY);

    if (sessionStorageItem) {
      const parsed: TokenInformationModel = JSON.parse(sessionStorageItem);

      if (parsed && parsed.inf) {
        const userData: UserDataModel = {
          hierarchyComplementaryInformation: parsed.inf.hierarchyComplementaryInformation,
          cpfCnpj: parsed.inf.cpfCnpj,
          userType: parsed.inf.userType,
          channel: parsed.inf.channel,
          institution: parsed.inf.institution,
          channelType: parsed.inf.channelType,
          serviceContract: parsed.inf.serviceContract,
        };
        return userData;
      }
    }
  }

  public setUserData(userData: UserDataModel) {
    const sessionStorageItem = sessionStorage.getItem(Constants.ACCREDITATION_USER_DATA_KEY);

    if (sessionStorageItem) {
      const parsed: TokenInformationModel = JSON.parse(sessionStorageItem);
      if (parsed && parsed.inf) {
        parsed.inf = userData;

        sessionStorage.setItem(Constants.ACCREDITATION_USER_DATA_KEY, JSON.stringify(parsed));
      }
    }
  }

  public isBinServiceContractProposal(serviceContract: number): boolean {
    return this.serivceContractBinAndSipag.includes(serviceContract);
  }

  public getUserDataSubChannel(userData: UserDataModel) {
    if (!userData.hierarchyComplementaryInformation || !userData.hierarchyComplementaryInformation.length) {
      return;
    }

    if (userData.hierarchyComplementaryInformation.length === 1) {
      return userData.hierarchyComplementaryInformation[0].subChannel;
    } else {
      const selected = userData.hierarchyComplementaryInformation.find((x) => !!x.selectedSubChannel);

      if (!selected) {
        return userData.hierarchyComplementaryInformation[0].subChannel;
      }

      return selected.subChannel;
    }
  }

  public getUserDataAgentChannel(userData: UserDataModel) {
    if (!userData.hierarchyComplementaryInformation || !userData.hierarchyComplementaryInformation.length) {
      return;
    }

    if (userData.hierarchyComplementaryInformation.length === 1) {
      return userData.hierarchyComplementaryInformation[0].agentId;
    } else {
      const selected = userData.hierarchyComplementaryInformation.find((x) => !!x.selectedAgentChannel);

      if (!selected) {
        return userData.hierarchyComplementaryInformation[0].agentId;
      }

      return selected.agentId;
    }
  }

  public getDraftDataFromLocalStorage() {
    const agentKey = this.getAgentCpfCnpjKey();

    const storedDraftData = localStorage.getItem(agentKey);
    if (storedDraftData) {
      const parsedDraftData = JSON.parse(storedDraftData) as LocalStorageData;

      if (!parsedDraftData) {
        return;
      }

      return parsedDraftData;
    }
  }

  public getAgentDataFromLocalStorage(draftUuid: string = null): LocalStorageAgentData {
    const draftData = this.getDraftDataFromLocalStorage();

    let activeDraft;
    if (draftUuid) {
      activeDraft = draftUuid;
    } else {
      activeDraft = sessionStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);
    }

    if (draftData) {
      return draftData[activeDraft];
    }
  }

  public clearAllLocalStorageObjects() {
    localStorage.clear();
  }

  public clearComercialSessionStorageObjects() {
    let register = this.getRegisterUserCode();
    sessionStorage.clear();
    this.setRegistrationUserCode(register);
  }

  public deleteDraftInformationFromLocalStorage(draftUuid: string) {
    const agentKey = this.getAgentCpfCnpjKey();

    const storedDraftData = localStorage.getItem(agentKey);
    if (storedDraftData) {
      const parsedDraftData = JSON.parse(storedDraftData) as LocalStorageData;

      if (!parsedDraftData) {
        return;
      }

      if (!draftUuid) {
        draftUuid = sessionStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);
      }

      delete parsedDraftData[draftUuid];

      localStorage.setItem(this.getAgentCpfCnpjKey(), JSON.stringify(parsedDraftData));
    }
  }

  public removeAgentFromLocalStorage() {
    localStorage.removeItem(this.getAgentCpfCnpjKey());
  }

  public setAgentDataOnLocalStorage(agentData: LocalStorageAgentData) {
    let storedData = this.getDraftDataFromLocalStorage();
    const activeDraftKey = sessionStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

    if (!storedData) {
      storedData = {
        [activeDraftKey]: agentData,
      };
    } else {
      storedData[activeDraftKey] = merge(storedData[activeDraftKey], agentData);
    }

    localStorage.setItem(this.getAgentCpfCnpjKey(), JSON.stringify(storedData));
  }

  public setSessionInformation(sessionInfo: SessionInformation, tokenInfo: TokenInformationModel, isCEF: boolean) {
    sessionStorage.removeItem(Constants.ACCREDITATION_TOKEN_KEY);
    sessionStorage.removeItem(Constants.ACCREDITATION_USERNAME_KEY);
    sessionStorage.removeItem(Constants.ACCREDITATION_USER_DATA_KEY);
    sessionStorage.removeItem(Constants.IS_CEF_KEY);

    sessionStorage.setItem(Constants.ACCREDITATION_TOKEN_KEY, sessionInfo.sessionToken);
    sessionStorage.setItem(Constants.ACCREDITATION_USERNAME_KEY, sessionInfo.name);
    sessionStorage.setItem(Constants.ACCREDITATION_USER_DATA_KEY, JSON.stringify(tokenInfo));

    sessionStorage.setItem(Constants.IS_CEF_KEY, JSON.stringify(isCEF));
  }

  public getCEFFlag(): boolean {
    const storageItem = sessionStorage.getItem(Constants.IS_CEF_KEY);

    return storageItem && JSON.parse(storageItem);
  }

  public clearSessionInformation() {
    sessionStorage.clear();
  }

  public getLocalStorageObject(key: string, draftUuid: string = null): any {
    const parsedAgentData = this.getAgentDataFromLocalStorage(draftUuid);

    if (parsedAgentData) {
      const storageItem = parsedAgentData[key];
      if (!storageItem) {
        return null;
      }

      return storageItem;
    }
  }

  public getAgentCpfCnpjKey() {
    const userData = this.getUserData();

    if (userData) {
      return userData.cpfCnpj;
    }
  }

  public getUsername() {
    return sessionStorage.getItem(Constants.ACCREDITATION_USERNAME_KEY);
  }

  public getToken() {
    return sessionStorage.getItem(Constants.ACCREDITATION_TOKEN_KEY);
  }

  public getHierarchyComplementaryInformation() {
    return sessionStorage.getItem(Constants.HIERARCHY_COMPLEMENTARY_INFORMATION);
  }
}
