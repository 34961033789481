import { FeeTypeEnum } from "src/app/order/enums/fee-type.enum";
import { ClientInformationModel } from "src/app/shared/models/simulation.model";

export interface EconomicalGroupSimulationModel {
  fees: Array<FeeDTO>;
  rateData: Array<RateDataDTO>;
  prepaymentFees: PrepaymentDataModel;
  technologies: TechnologyPriceDataModel[];
  exemptionPolicy: ExemptionPolicyDataModel;
}

export interface EconomicGroupClientInformationModel extends ClientInformationModel{
  isPrepayment: boolean;
  mccNumber: number;
}

export class EconomicalGroupSimulationRequestModel {
  mccNumber: number;
  annualBilling: string;
  uf: string;
  prepayment: boolean;
}

export class FeeDTO {
  brand: string;
  modality: string;
  fee: number;
  minimumRate: number;
  feeTypeEnum: FeeTypeEnum;
}

export class PrepaymentDataModel {
  public annualBilling: number;
  public suggestedManualRate: number;
  public suggestedAutoPrepayment: number;
}


export class TechnologyPriceDataModel {
  public tipoTerminalDesc: string;
  public valor: number;
}

export class ExemptionPolicyDataModel {
  public description: string;
  public exemptionFees: ExemptionFeeDataModel[];
}

export class ExemptionFeeDataModel {
  public minRange: number;
  public maxRange: number;
  public discountPercent: number;
  public qtdEquipment: number;

}
export class RateDataDTO {
  public flagName: string;
  public debit: number;
  public credit: number;
  public lessTo6Installments: number;
  public more6Installments: number;
  public installmentPlan: number;
}


interface PrepaymentRateConfigDTO {
  minManualRate: number;
  suggestedManualRate: number;
  minAutoPrepayment: number;
  suggestedAutoPrepayment: number;
  annualBilling: number;
}

export class EconomicalGroupSimulationDetailModel {
  cnpj: string;
  cnpjStatus: string;
  orientation: string;
}
