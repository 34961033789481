import { Component, Input, OnInit } from '@angular/core';
import { getValueWithDecimalPlaces } from 'src/app/order/utils/decimal-places';

@Component({
  selector: 'app-simulation-economical-group-pricing-table',
  templateUrl: './simulation-economical-group-pricing-table.component.html',
  styleUrls: ['./simulation-economical-group-pricing-table.component.scss']
})
export class SimulationEconomicalGroupPricingTableComponent implements OnInit {

  @Input() data: any;
  columns: string[] = ["brandName", "debitFee", "creditFee", "creditFeeLessSixInstallment", "creditFeeGreaterSixInstallment", "installmentPlan"];
  organizedData: any[] = [];

  ngOnInit(): void {
    // This is intentional: para correção de codesmell do sonarqube para métodos vazios
  }

  public formatFee (fee: number): string{
    return getValueWithDecimalPlaces(fee);
  }

  public getFlagImageURL(flag: string): string {
    const flagLowerCase = flag.toLowerCase();
    return `../assets/images/table-flags/${flagLowerCase}.png`;
  }

}
