import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.local";
import { EconomicalGroupSimulationDetailModel, EconomicalGroupSimulationModel, EconomicalGroupSimulationRequestModel } from "src/app/order/components/simulation-economical-group/models/economical-group-simulation.model";
import { Pageable } from "src/app/shared/models/pageable.model";
import { CommercialConditionsHistoryDTO } from "src/app/order/components/simulation-economical-group/models/commercial-conditions-history.model";
import { SimulationDecryptResponseModel, SimulationSearchResponse } from "src/app/order/components/simulation-economical-group/models/simulation-search.model";
import { EconomicGroupCreationResponse } from "src/app/order/components/simulation-economical-group/models/economic-group-creation-response.model";
import { CreateEconomicGroupDTO } from "src/app/order/components/simulation-economical-group/models/create-economic-group-dto.model";

@Injectable({
  providedIn: "root",
})
export class EconomicGroupService {
  agentChannel: string;

  constructor(
    private http: HttpClient
  ) {
    this.agentChannel = "";
  }

  checkGroup(cnpj: string): Observable<boolean> {
    const params = new HttpParams().set("cnpj", cnpj);
    return this.http.get<boolean>(
      `${environment.apiUrls.economicGroup}/check`,
      { params }
    );
  }

  getTemplate() {
    return this.http.get(
      `${environment.apiUrls.economicalGroupConvenience}/cnpjs/template`,
      { responseType: "blob" as "json" }
    );
  }

  createGroup(createEconomicGroupDTO : CreateEconomicGroupDTO): Observable<EconomicGroupCreationResponse>{
    return this.http.post<EconomicGroupCreationResponse>(`${environment.apiUrls.economicGroupCreation}`,createEconomicGroupDTO);
  }

  saveMassive(formData: FormData) {
    return this.http.post(`${environment.apiUrls.economicalGroupConvenience}/save-massive`, formData);
  }

  getSimulation(request: EconomicalGroupSimulationRequestModel) {
    let params = new HttpParams();
    params = params.append('mccNumber', request.mccNumber)
                  .append('annualBilling', request.annualBilling)
                  .append('uf', request.uf)
                  .append('prepayment', request.prepayment);
    return this.http.get<EconomicalGroupSimulationModel>(`${environment.apiUrls.comercialConditions}/pre-simulation`, {params});
  }

  getSimulationList(managerCod: string,cnpj: string,page: number,size: number) {
    let params = new HttpParams();
    params = params.append('managerCod', managerCod)
                  .append('cnpj', cnpj)
                  .append('page', page)
                  .append('size', size);;
    return this.http.get<Pageable<SimulationSearchResponse[]>>(`${environment.apiUrls.economicGroupSimulation}`, {params});
  }

  decryptParamsSimulationSearch(key: string,page: number,size: number): Observable<any>{
    key = key.replace(/\//g, "_");
    let params = new HttpParams();
    params = params.append('page', page)
                  .append('size', size);
    return this.http.get<SimulationDecryptResponseModel>(`${environment.apiUrls.economicGroupSimulation}/decrypt-param/${key}`,{params});
  }

  getEconomicGroupSimulationDetail(id: number, size: number, page: number): Observable<Pageable<EconomicalGroupSimulationDetailModel[]>> {
    return this.http.get<Pageable<EconomicalGroupSimulationDetailModel[]>>(`${environment.apiUrls.economicGroupSimulation}/details/${id}?page=${page}&size=${size}`);
  }

  getEconomicGroupSimulationPricingDetail(simulationCod: number): Observable<CommercialConditionsHistoryDTO> {
    return this.http.get<CommercialConditionsHistoryDTO>(`${environment.apiUrls.comercialConditions}/history/${simulationCod}`);
  }

  checkEconomicGroupSimulation(simulationCod: number): Observable<Boolean> {
    return this.http.get<Boolean>(`${environment.apiUrls.economicGroupSimulation}/check/${simulationCod}`);
  }

  getContractFile(simulationCod: number, managerCod: string): Observable<any>{
    return this.http.post(`${environment.apiUrls.economicGroupSimulation}/contract/${simulationCod}/${managerCod}`, null,{
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

}
