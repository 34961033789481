import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PersonTypeEnum } from 'src/app/order/enums/person-type.enum';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { stateList } from 'src/app/order/utils/state-list';
import { FdRadioButtonConfig } from 'src/app/shared/fd-form-components/fd-radio-button/fd-radio-button.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { ClientInformationModel } from 'src/app/shared/models/simulation.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { EconomicGroupClientInformationModel } from '../models/economical-group-simulation.model';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { Messages } from 'src/app/order/messages/order.messages';

@Component({
  selector: 'app-economical-group-form',
  templateUrl: './economical-group-form.component.html',
  styleUrls: ['./economical-group-form.component.scss']
})
export class EconomicalGroupFormComponent implements OnInit {
  private readonly CEF_SERVICE_CONTRACT = '149';
  private readonly BILLING_VALUE_30K="30000";

  @Input() clientInformation = new ClientInformationModel();

  @Output() consultOffers = new EventEmitter<EconomicGroupClientInformationModel>();

  isCnaeLoaded: Subject<boolean> = new Subject();
  formGroup: UntypedFormGroup = this.createFormGroup();
  fields: FdFieldConfigs = this.createFields();

  constructor(private formBuilder: UntypedFormBuilder,
    private cnaeService: CnaeService,
    protected dialogService: DialogService) { }

  ngOnInit(): void { this.checkParams() }

  get mccDropdown() {
    return (this.fields.mccNumber as FdSelectConfig);
  }

  get formControls(): {[key: string]: AbstractControl} {
    return this.formGroup.controls;
  }

  private checkParams() {
    this.formControls.billingValue.setValue(this.clientInformation.billingValue);
    (this.fields.isPrepayment as FdRadioButtonConfig).items = this.optionsPrepayment;
    this.formControls.isPrepayment.setValue(false);
  }

  private createFormGroup() {
    return this.formBuilder.group({
      mccNumber: ['', Validators.required],
      state: ['', Validators.required],
      billingValue: ['', [Validators.required, Validators.min(0.01), Validators.max(999999999)]],
      annualBillingValue: ['', [Validators.required]],
      isPrepayment: [false, [Validators.required]]
    });
  }

  getMccList(filterValue: string) {

    if (!filterValue || !filterValue.length) {
      return;
    }

    if (filterValue.length < 3) {
      this.mccDropdown.items = [];
      return;
    }

    let mcc = Number(filterValue)

    this.cnaeService.getMccList(mcc)
      .pipe(take(1))
      .subscribe(mccList => {
        if (mccList != null) {
          this.mccDropdown.items = [];
          this.mccDropdown.items.push(...mccList);
        }
      },
      () => {
        this.mccDropdown.items = [];
      });
  }

  public showFeeData() {
    if(this.isBillingValueLessThanOrEqual30k()){
      this.dialogService.openDialogWithHtmlMessage(Messages.BILLING_VALUE_LESS_THAN_OR_EQUALS_30K);
      return;
    }

    const personType = PersonTypeEnum.PHYSICAL;

    this.consultOffers.emit({...this.formGroup.getRawValue(), personType});
  }

  isBillingValueLessThanOrEqual30k(){
    let billingValue = this.formGroup.controls['billingValue'].value;
    return (billingValue <= this.BILLING_VALUE_30K)
  }

  billingValuesChange(value) {
    if(value) {
      let number = value.replace(/[^0-9,-]+/g,"")
      number = Number(number.replace(',', '.'))
      if(number) {
        number *= 12;
        this.formGroup.get('annualBillingValue').setValue(number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
      }      
    }    
  }

  protected createFields() {
    return {
      mccNumber: {
        label: 'Informe o MCC',
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o MCC',
        controlName: 'mccNumber',
        messages: {
          required: 'informe o MCC',
          invalid: 'MCC inválido'
        }
      },
      state: {
        label: 'Estado (UF)',
        items: stateList,
        controlName: 'state',
        messages: {
          required: 'informe o estado',
          invalid: 'Selecione um estado'
        }
      },
      billingValue: {
        label: 'Informe faturamento mensal do grupo no cartão',
        controlName: 'billingValue',
        messages: {
          required: 'informe o faturamento mensal',
          invalid: 'faturamento inválido',
          min: 'faturamento inválido',
          max: 'faturamento inválido'
        }
      },
      annualBillingValue: {
        label: 'Faturamento anual do grupo no cartão',
        controlName: 'annualBillingValue',
        disabled: true,
        messages: {}
      },
      isPrepayment: {
        items: this.optionsPrepayment,
        controlName: 'isPrepayment',
        messages: {
          required: 'Informe o tipo de antecipação',
          invalid: 'Informe o tipo de antecipação'
        }
      }
    }
  }

  private optionsPrepayment: Item[] = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
      selected: true,
    },
  ];
}
