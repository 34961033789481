import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const CpfValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {

  const value: string = control.value ? control.value.replace(/\D/g, '') : '';

  if (isCpfValid(value)) {
    return { 'invalidCpf': true };
  }

  const numbers: number[] = [];

  for (let i = 0; i < 11; i++) {
    numbers[i] = Number(value[i].toString());
  }

  let sumValue = 0;

  for (let i = 0; i < 9; i++) {
    sumValue += (10 - i) * numbers[i];
  }

  let resultValue = sumValue % 11;

  let digito9Valido = digitoVerificadorValido(9, numbers, resultValue);
  if (!digito9Valido) { 
      return { 'invalidCpf': true };   
  }

  sumValue = 0;

  for (let i = 0; i < 10; i++) {
    sumValue += (11 - i) * numbers[i];
  }

  resultValue = sumValue % 11;

  let digito10Valido = digitoVerificadorValido(10, numbers, resultValue);
  if (!digito10Valido) { 
      return { 'invalidCpf': true };   
  }

  return {};
};

function digitoVerificadorValido(digito, numeros, resto) {
  
  if (resto === 1 || resto === 0) {
    if (numeros[digito] !== 0) {
      return false
    }
  } else {
    if (numeros[digito] !== 11 - resto) {
      return false;
    }
  }

  return true;
}

function isCpfValid(value) {

  if (!value) {
    return false;
  }

  if (value.length !== 11) {
    return false;
  }

  let isEqual = true;

  for (let i = 1; i < 11 && isEqual; i++) {
    if (value[i] !== value[0]) {
      isEqual = false;
      break;
    }
  }

  return (!isEqual && value !== '12345678909');
}