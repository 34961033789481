import { Component, Input, Output, EventEmitter} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialogRef } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { EconomicalGroupSimulationDetailModel } from "../../../simulation-economical-group/models/economical-group-simulation.model";

@Component({
    selector: 'app-simulation-economical-group-list-cnpjs',
    templateUrl: './simulation-economical-group-list-cnpjs.component.html',
    styleUrls: ['./simulation-economical-group-list-cnpjs.component.scss'],
  })
export class SimulationEconomicalGroupListCnpjs {

  @Input() dataSource: MatTableDataSource<EconomicalGroupSimulationDetailModel>;
  displayedColumns = ['CNPJ', 'Status do CNPJ', 'Orientação'];
  @Input() sizeItems = 5;
  @Input() length = 0;
  pageNumber = 0;
  @Output() changePage = new EventEmitter<PageEvent>();
  defaultSizeItems = 1;

  constructor(
    public dialogRef: MatDialogRef<EconomicalGroupSimulationDetailModel>) {
    
  }

  get hasValue() {
    return this.dataSource && this.dataSource.data.length > 0;
  }

  close() {
    this.dialogRef.close();
  }
  

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }
}