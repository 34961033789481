import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { environment } from 'src/environments/environment.local';
import { CnaeModel } from 'src/app/shared/models/response/response-cnae.model';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { MccModel } from 'src/app/shared/models/response/response-mcc.model';
import { CnaeAndMccResponseDTO } from 'src/app/order/components/simulation-economical-group/models/cnae-and-mcc-response-dto.model';
import { Pageable } from 'src/app/shared/models/pageable.model';

@Injectable({
  providedIn: 'root',
})
export class CnaeService {

  agentChannel: string;

  constructor(private http: HttpClient) {
      this.agentChannel = '';
  }

  getCnaeList(filterValue: string): Observable<Item[]> {
    const filterQuery = filterValue ? `?partial-cnae=${filterValue}` : '';
    return this.http.get<BaseConfigApiResponse<CnaeModel[]>>(`${environment.apiUrls.cnaeSimulator}${filterQuery}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToCnaeModels(x.data)));
  }

  getMccList(filterValue): Observable<Item[]> {
    const filterQuery = filterValue ? `?partialmcc=${filterValue}` : '';
    return this.http.get<MccModel[]>(`${environment.apiUrls.mccSimulator}${filterQuery}`)
      .pipe(map(x => this.mapToMccModels(x)));
  }

  getCnaeListByMcc(mcc): Observable<CnaeModel[]> {
    return this.http.get<CnaeModel[]>(`${environment.apiUrls.mccSimulator}/${mcc}`);
  }

  checkCnae(cnae: string, serviceContract: string) {
    const params = new HttpParams().set('cnae', cnae).set('serviceContract', serviceContract);
    return this.http.get<boolean>(`${environment.apiUrls.cnaeSimulator}/check`, {params});
  }

  getCnaeAndMccInfo(partialCnae, partialMcc){
    const params = new HttpParams().set('partialCnae',partialCnae).set('partialMcc',partialMcc);
    return this.http.get<Pageable<CnaeAndMccResponseDTO[]>>(`${environment.apiUrls.mccSimulator}/cnae-and-mcc-info`, {params});
  }

  private mapToCnaeModels(response: CnaeModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.cnaeNumber,
      label: value.cnaeNumber.toString() + ' - ' + value.cnaeDescription,
      additionalProperties: {
        status: value.status,
        ecommerceExceptionCnae: value.ecommerceExceptionCnae
      }
    }));
  }

  private mapToMccModels(response: MccModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.mcc,
      label: value.mccDescription
    }));
  }

  private invalidResponse(baseApiResponse: BaseConfigApiResponse<CnaeModel[]>): boolean {
    return !baseApiResponse ||
      !!baseApiResponse.error ||
      !baseApiResponse.data;
  }

  private convertToCurrency(value: number) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
}
