import { OnInit, Directive } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ScrollService } from '../services/internal/scroll/scroll.service';

@Directive()
export abstract class FormBase implements OnInit {

  constructor(
    protected scrollService: ScrollService
  ) {
  }

  public formGroup: UntypedFormGroup;
  protected abstract createFormGroup(): UntypedFormGroup;

  ngOnInit(){
    this.formGroup = this.createFormGroup();
  }


  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private showErrorMessages(formToBeValidated: UntypedFormGroup | UntypedFormArray){
    let control;
    Object.keys(formToBeValidated.controls)
    .reverse()
    .forEach(field => {
      control = formToBeValidated.get(field);
      if (control instanceof UntypedFormArray ||
          control instanceof UntypedFormGroup) {
        this.showErrorMessages(control);
      } else {
        if (control && control.invalid) {
          control.markAsTouched();
        }
      }
    });

    if (control) {
      this.scrollService.scrollAndFocusToFirstInvalid();
    }
  }
  public isFormGroupValid(formGroup?: UntypedFormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

}
