import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})

export class MessageModalComponent implements OnInit {

  @Output() buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<MessageModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MessageModalDialogData,
              @Inject(DOCUMENT) private document: Document) { }

  async ngOnInit() {
    // This is intentional: Correção de sonarqube codesmell para empty methods.
  }

  handleClick(): void {
    if (this.data.callbackFn) {
      this.data.callbackFn();
    }
    this.dialogRef.close();
  }

  getButtonContent() {
    return (this.data && this.data.buttonText) || 'fechar';
  }

}

export interface MessageModalDialogData {
  title: string;
  description: string;
  buttonText: string;
  html?: boolean;
  callbackFn: () => any;
}


