import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, OnDestroy } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faBars, faCamera, faInfoCircle, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { InformationModalComponent } from './components/modals/information-modal/information-modal.component';
import { MessageModalComponent } from './components/modals/message-modal/message-modal.component';
import { AdditionalInformationComponent } from './components/simulation-data/additional-information/additional-information.component';
import { ClientInformationComponent } from './components/simulation-data/client-information/client-information.component';
import { FeesDataComponent } from './components/simulation-data/fees-data/fees-data.component';
import { NegotiationModalComponent } from './components/simulation-data/negotiation-modal/negotiation-modal.component';
import { SimulationTableComponent } from './components/simulation-data/simulation-table/simulation-table.component';
import { OrderStepGuard } from './guards/step-guard';
import { CnaeService } from './services/external/cnae/cnae.service';
import { LoadingService } from './services/internal/loading/loading.service';
import { ScrollService } from './services/internal/scroll/scroll.service';
import { SimulationComponent } from './simulation.component';
import { GroupPricingComponent } from './components/group-pricing/group-pricing.component';
import { SimulationEconomicalGroup } from './components/simulation-economical-group/simulation-economical-group.component';
import { EconomicalGroupFormComponent } from './components/simulation-economical-group/economical-group-form/economical-group-form.component';
import { SimulationEconomicalGroupTableComponent } from './components/simulation-economical-group/simulation-economical-group-table/simulation-economical-group-table.component';
import { SimulationSearch } from './components/simulation-search/simulation-search.component';
import { SimulationEconomicalGroupDetail } from './components/simulation-search/simulation-economical-group-detail/simulation-economical-group-detail.component';
import { SimulationEconomicalGroupListCnpjs } from './components/simulation-search/simulation-economical-group-detail/simulation-economical-group-list-cnpjs/simulation-economical-group-list-cnpjs.component';
import { SimulationEconomicalGroupPricingDetailComponent } from './components/simulation-search/simulation-economical-group-pricing-detail/simulation-economical-group-pricing-detail.component';
import { SimulationEconomicalGroupPricingTableComponent } from './components/simulation-search/simulation-economical-group-pricing-detail/simulation-economical-group-pricing-table/simulation-economical-group-pricing-table.component';
import {
  PrepaymentTableComponent
} from "./components/simulation-economical-group/prepayment-table/prepayment-table.component";
import {
  TechnologyPriceTableComponent
} from "./components/simulation-economical-group/technology-price-table/technology-price-table.component";
import { CnaeAndMccSearch } from './components/cnae-and-mcc-search/cnae-and-mcc-search.component';
import { ExemptionPolicyTableComponent } from './components/simulation-economical-group/exemption-policy-table/exemption-policy-table.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { SimulationTableFlagComponent } from './components/simulation-data/simulation-table-flag/simulation-table-flag.component';



registerLocaleData(ptBr);

@NgModule({
    declarations: [
        SimulationComponent,
        MessageModalComponent,
        ErrorModalComponent,
        InformationModalComponent,
        ClientInformationComponent,
        GroupPricingComponent,
        FeesDataComponent,
        SimulationTableComponent,
        AdditionalInformationComponent,
        NegotiationModalComponent,
        SimulationEconomicalGroup,
        EconomicalGroupFormComponent,
        SimulationEconomicalGroupTableComponent,
        PrepaymentTableComponent,
        TechnologyPriceTableComponent,
        SimulationEconomicalGroupDetail,
        SimulationEconomicalGroupListCnpjs,
        SimulationSearch,
        SimulationEconomicalGroupPricingDetailComponent,
        SimulationEconomicalGroupPricingTableComponent,
        CnaeAndMccSearch,
        ExemptionPolicyTableComponent,
        SimulationTableFlagComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatGridListModule,
        FlexLayoutModule,
        SharedComponentsModule,
        FontAwesomeModule,
        MatSelectInfiniteScrollModule,
        NgxSpinnerModule,
        MatTableModule,
        MatExpansionModule,
        MatIconModule,
        MatDialogModule,
        MatPaginatorModule ,
        MatToolbarModule,
        MatTabsModule,
        MaterialFileInputModule
    ],
    providers: [
        OrderStepGuard,
        CnaeService,
        CurrencyPipe,
        { provide: LOCALE_ID, useValue: 'pt-PT' }
    ]
})
export class OrderModule implements OnDestroy {
  alive = true;

  constructor(private spinner: NgxSpinnerService,
              private loadingService: LoadingService,
              private scrollService: ScrollService) {
    library.add(faArrowLeft, faCamera, faPlusCircle, faTimes, faBars, faInfoCircle);
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
