import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.local';

@Injectable({
    providedIn: 'root',
})
export class SimulatorConfigService {
    constructor(private http: HttpClient) {}

    public getAblePriceSimulator(): Promise<boolean> {
        return this.http.get<boolean>(`${environment.apiUrls.simulationConfig}/economic-group/enabled`).toPromise().then(
            (response) =>{
              return response;
            },
            (error) =>{
              return false;
            }
          );
    }

    public getAbleSimulatorAudit(): Promise<boolean> {
      return this.http.get<boolean>(`${environment.apiUrls.simulationConfig}/simulator-audit/enabled`).toPromise().then(
          (response) =>{
            return response;
          },
          (error) =>{
            return false;
          }
        );
  }
    
}