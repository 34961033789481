import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScrollService } from '../scroll/scroll.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  // Variáveis para controlar o estado do loading
  private loadingActive: boolean;
  private loadingActive$: BehaviorSubject<boolean>;

  // Variáveis para controle da lista de eventos de loading
  private requests$ = new BehaviorSubject<string[]>([]);
  private url: string[] = [];

  constructor(private spinner: NgxSpinnerService, private scrollService: ScrollService) {
    this.loadingActive = false;
    this.loadingActive$ = new BehaviorSubject(this.loadingActive);
    this.eventoLoading();
  }

  public getLoadingStatus(): Observable<boolean> {
    return this.loadingActive$;
  }

  public isLoadingActive() {
    return this.loadingActive;
  }

  // Adciona um evento de loading que consequentemente bloqueará a tela
  public adicionaLoadingEvent(requestUrl: string) {
    if (!this.url.includes(requestUrl)) {
      this.url.push(requestUrl);
      this.requests$.next(this.url);
    }
  }

  // Remove um evento de loading
  // Caso a lista de eventos de loading fique vazia a tela será desbloqueada
  public removeLoadingEvent(requestUrl: string) {
    const index = this.url.findIndex((url) => url === requestUrl);

    if (index >= 0) {
      this.url.splice(index, 1);
      this.requests$.next(this.url);
    }
  }

  public showInitialBloq() {
    this.loadingActive = true;
    this.loadingActive$.next(this.loadingActive);
  }

  // Função que inicia bloqueio da tela
  private show() {
    this.loadingActive = true;
    this.loadingActive$.next(this.loadingActive);
    this.scrollService.focusToElement('.fd-spinner .overlay');
    this.spinner.show();
  }

  // Função que finaliza o bloqueio da tela
  public hide() {
    this.loadingActive = false;
    this.loadingActive$.next(this.loadingActive);
    this.spinner.hide();
  }

  // Função que observa as alterações da lista de eventos para controlar o bloqueio da tela
  private eventoLoading() {
    this.requests$.asObservable().subscribe((requests) => {
      if (requests.length) {
        this.show();
      } else {
        this.hide();
      }
    });
  }
}
