import { Component, OnInit } from '@angular/core';

import packageJson from 'package.json';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  public version = packageJson.version;

  ngOnInit() {
    // This is intentional: para correção de codesmell do sonarqube para métodos vazios
  }

}
