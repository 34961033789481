import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {PrepaymentDataModel} from "../models/economical-group-simulation.model";
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-prepayment-table',
  templateUrl: './prepayment-table.component.html',
  styleUrls: ['./prepayment-table.component.scss'],
})
export class PrepaymentTableComponent implements OnChanges {
  @Input()
  public data: PrepaymentDataModel[];

  public dataSource: MatTableDataSource<PrepaymentDataModel> = new MatTableDataSource();
  public displayedColumns = ['annualBilling', 'suggestedAutoPrepayment'];

  constructor(private decimalPipe: DecimalPipe) {}


  public ngOnInit() {
    this.dataSource.data = this.data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.dataSource.data = this.data;
    }
  }

  public formatPercentage(number: number) {
    let numberFormatted = this.decimalPipe.transform(number, '1.2-2', 'pt');

    if(numberFormatted != null && numberFormatted != '0') {
      return numberFormatted.toString().concat(' %');
    }

    return '--';
  }


}
