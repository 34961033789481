<mat-form-field class="fd-currency-input__container" color="accent">
  <div [formGroup]="parentForm">
      <input matInput
          currencyMask
          [formControlName]="field?.controlName" autocomplete="off" class="fd-currency-input__field" [placeholder]="field?.label"
          [maxlength]="field?.maxLength || 21"
          [options]="getOptions()"
          [id]="field?.controlName"
          [required]="required || null" aria-label="Area code"
          type="tel"
          autocomplete="off"
          [attr.disabled]="field?.disabled || null"
          tabindex="0"
          (keydown)="handleKeyDown($event)"
          (keyup)="handleKeyUp($event)"
          (click)="handleFocus($event)"
          (blur)="handleBlur($event)"
          (input)="handleChange($event)">
  </div>
  <mat-error *ngIf="hasError"><div *ngFor="let errorMessage of errorMessages">{{errorMessage}}</div></mat-error>
</mat-form-field>
