<div class="fd-accreditation-form__container">
  <div *ngIf="!progressiveDiscountTable">
    <div *ngIf="!negotiationForm">
      <div class="forms" fxLayout="column" fxLayoutAlign="center stretch">
        <h4 class="fd-subtitle">SIMULADOR DE TAXAS</h4>
        <app-client-information
          *ngIf="viewControl.clientInformation"
          [clientInformation]="proposal.clientInformation"
          (consultOffers)="showFeesData($event)"
          (cleanDown)="clear()"
        ></app-client-information>

        <app-fees-data
          *ngIf="viewControl.feesData"
          [clientInformation]="proposal.clientInformation"
          [selectedCampaing]="offerQueryData.campaignId"
          (feeInfoEvent)="showOfferData($event)"
        >
        </app-fees-data>

      <app-simulation-table
        *ngIf="viewControl.advanceFeeTableData"
        [dataSource]="advanceFee$ | async"
        [objectProperties]="advanceFeeProperties"
        tableName="Taxa de antecipação automática">
      </app-simulation-table>

      <app-simulation-table
        *ngIf="viewControl.factorTableData"
        [dataSource]="factorFees$ | async"
        [objectProperties]="factorFeesProperties"
        tableName="{{viewControl.offerTableDataLotericaPinpad ? 'Serviço de Conveniência' : 'Fator'}}">
      </app-simulation-table>

      <h4 *ngIf="viewControl.offerTableData && !viewControl.offerTableDataLotericaPinpad" class="fd-subtitle" >Taxas MDR</h4>
      <app-simulation-table-flag *ngIf="viewControl.offerTableData && !viewControl.offerTableDataLotericaPinpad" [tableName]="'Taxas MDR'" [data]="genericMdrFee$ | async"></app-simulation-table-flag>

      <app-additional-information
        *ngIf="viewControl.offerTableData"
        [enableSimulatorAudit]="enableSimulatorAudit"
        [offerQueryData]="offerQueryData" (sendTechsInformation)="buildTechs($event)">
      </app-additional-information>
      </div>
    </div>

    <div *ngIf="negotiationForm && !createGroupForm">
      <app-negotiation-modal
        [clientInformation]="proposal.clientInformation"
        [isPrepaymentEnable]="offerQueryData.isPrepaymentEnabled"
        [enableSimulatorAudit]="enableSimulatorAudit"
        [simulationInformation]="getSimulationInformation()"
        (backToMain)="closeNegotiationForm()"
      ></app-negotiation-modal>
    </div>

    <div
      *ngIf="
        !negotiationForm &&
        (viewControl.offerTableData || viewControl.offerTableDataLoterica)
      "
      class="fd-accreditation-form__container__actions"
    >
      <fd-button
        class="no-print"
        [content]="'imprimir'"
        *ngIf="offerQueryData"
        (trigger)="printOffer()"
      >
      </fd-button>

      <fd-button
        class="no-print"
        [content]="'Solicitar negociação'"
        (trigger)="openNegotiationForm()"
        *ngIf="
          offerQueryData &&
          !viewControl.offerTableDataLoterica &&
          offerQueryData.hasNegotiation
        "
      >
      </fd-button>

      <fd-button
        class="no-print"
        [content]="'Prosseguir para Contratação'"
        (trigger)="finishSimulation()"
      >
      </fd-button>
    </div>
  </div>

</div>
