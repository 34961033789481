<div *ngIf="dataSource" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="center center" style="border-bottom: 1px solid lightgray;">
        <h5 class="title"> Detalhes do Grupo </h5>
    </div>
    <app-simulation-economical-group-list-cnpjs [dataSource]="dataSource" [sizeItems]="sizeItems" [length]="length"
    (changePage)="changePage($event)"></app-simulation-economical-group-list-cnpjs>
    <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 10%;">
        <fd-button
        class="no-print"
        [content]="'FECHAR'"
        (trigger)="close()"
        ></fd-button>
    </div>
</div>