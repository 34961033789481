import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from 'src/app/order/services/internal/auth/auth.service';
import {RoutingService} from 'src/app/order/services/internal/routing/routing.service';
import {DataStoreService} from 'src/app/order/store/data-store.service';
import {environment} from 'src/environments/environment.local';
import CryptoES from 'crypto-es';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private dataStoreService: DataStoreService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headerApiGee = JwtInterceptor.getHeadersApiGee(request);

    request = request.clone({
        setHeaders: {
          'Api-Key': `${environment.apiGee.apiKey}`,
          'Timestamp': `${headerApiGee.timestamp}`,
          'Message-Signature': `${headerApiGee.messageSignature}`,
          'Client-Request-Id': `${environment.apiGee.clientSecretId}`,
        },
      });
      return next.handle(request);

  }

  private static getHeadersApiGee(request: HttpRequest<any>) {
    let apikey = environment.apiGee.apiKey
    let apisecret =  environment.apiGee.apiKeySecret
    let clientRequestId = environment.apiGee.clientSecretId;
    let now = new Date();
    let timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    let authString = apikey + clientRequestId + timestamp;
    if (request.body && Object.keys(request.body).length) {
        let bodyString = JSON.stringify(request.body);
        authString += bodyString;
    }
    let hmac = CryptoES.HmacSHA256(authString, apisecret).toString();
    let authHmac = btoa(hmac);
    return {timestamp: timestamp, messageSignature: authHmac}
  }


}
