  <form *ngIf="!!formGroup && !!fields" [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="center stretch">

      <div>
        <fd-select
          (loadItemsFn)="getMccList($event)"
          [parentForm]="formGroup"
          [field]="fields.mccNumber"
        >
        </fd-select>
      </div>

      <div>
        <fd-select [parentForm]="formGroup" [field]="fields.state"> </fd-select>
      </div>

      <div fxLayout="line" style="justify-content: space-between;">
        <fd-currency-input [parentForm]="formGroup" (change)="billingValuesChange($event)" [field]="fields.billingValue" style="width: 49%;"></fd-currency-input>

        <fd-input  [parentForm]="formGroup" [field]="fields.annualBillingValue" style="width: 49%;"></fd-input >
      </div>

        <h4 class="fd-subtitle">Deseja antecipar?</h4>
        <fd-radio-button
          [parentForm]="formGroup"
          [field]="fields.isPrepayment"
        >
        </fd-radio-button>

      <fd-button
        [disabled]="this.formGroup.invalid"
        content="consultar taxas"
        (trigger)="showFeeData()"
      >
      </fd-button>
    </div>
  </form>
