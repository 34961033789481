export function sanitizeSearchFilter(value: string) {
    return value.trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''); // remove accents and spaces
}

export function sanitizeCpfCnpj(value: string){
    return value.trim().replace(/[^\d]+/g,'');
                                
}