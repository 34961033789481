<div *ngIf="dataSource.data">
  <table mat-table [dataSource]="dataSource" class="fd-table" aria-label="List of fees by type of charge and card flag">
    <!-- ID Column -->

    <ng-container matColumnDef="flagName" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Bandeira</th>
      <td mat-cell *matCellDef="let rowElement">
        <img [class]="rowElement.flagName" [src]="getFlagImageURL(rowElement.flagName)" [alt]="rowElement.flagName"/>
      </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="credit" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Crédito</th>
      <td class="align-center" mat-cell *matCellDef="let rowElement">{{ formatPercentage(rowElement.credit) }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="debit" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Débito</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ formatPercentage(rowElement.debit) }}</td>
    </ng-container>

    <ng-container matColumnDef="lessTo6Installments" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Parcelamento em até 6x</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ formatPercentage(rowElement.lessTo6Installments) }}</td>
    </ng-container>

    <ng-container matColumnDef="more6Installments" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Parcelamento mais de 6x</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ formatPercentage(rowElement.more6Installments) }}</td>
    </ng-container>

    <ng-container matColumnDef="installmentPlan" class="fd-table__cell mat-column-installmentPlan">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Crediário</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ formatPercentage(rowElement.installmentPlan) }}</td>
    </ng-container>

    <!-- Color Column -->

    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
