import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FirstPageEconomicGroup } from '../side-nav.items';

@Component({
  selector: 'side-nav',
  templateUrl: './client-information-side-nav.html',
  styleUrls: ['./client-information-side-nav.scss']
})
export class ClientInformationSideNavComponent {
  navItems = [FirstPageEconomicGroup];

  constructor(
    private route: Router
  ) { }

  navigate(link: string): void{
    this.route.navigate([link]);
  }
}
