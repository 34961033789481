import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { TokenInformationModel } from 'src/app/shared/models/response/auth-service.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  getDecodedAccessToken(token: string): TokenInformationModel {
    try {
      const decoded = jwt_decode<TokenInformationModel>(token);
      return decoded;
    }
    catch (Error) {
      console.log("[token.service] - Error when decoding token");
      return null;
    }
  }
}
