
<div class="fd-accreditation-form__container">
    <div class="forms" fxLayout="column" fxLayoutAlign="center stretch">
      <h4 class="fd-subtitle">BUSCA DE CNAE E MCC</h4>
      <form *ngIf="!!formGroup && !!fields" [formGroup]="formGroup">
        <div fxLayout="row" >
          <div fxLayoutGap="50px">
            <div fxFlex="31">
            <h4 class="fd-subtitle" >Escolha o que deseja consultar: </h4>
          </div>
          <div fxFlex="25">
            <fd-radio-button  [display]="'flex'"  [buttonPad]= "'40px'"
            (change)="changeMccOrCnaeInput($event)"
            [parentForm]="formGroup"
            [field]="fields.filterOptions"
          >
          </fd-radio-button>  
          </div>
          <div fxFlex="25">
            <fd-input *ngIf="option == 'mcc'" [parentForm]="formGroup" [field]="fields.mcc"> </fd-input>
            <fd-input *ngIf="option == 'cnae'" [parentForm]="formGroup" [field]="fields.cnae"> </fd-input>
          </div>
          <div  fxFlex="1"  >
            <button class="custom-button" (click)="getMccAndCnaeList()"
               matTooltip ="Buscar">
              BUSCAR
          </button>
          </div>
          </div>
        </div>
      </form>
      </div>
    
    <div *ngIf="hasValue" >
    <table *ngIf="hasValue"  mat-table [dataSource]="dataSource" class="table" style="width: 100%">
      <caption>
        Tabela com a lista de cnaes e mcc filtrados
      </caption>
      <ng-container matColumnDef="cnae">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>MCC</th>
        <td mat-cell *matCellDef="let element">{{ element.mcc }} </td>
      </ng-container>
  
      <ng-container matColumnDef="mcc">
          <th mat-header-cell *matHeaderCellDef>CNAE</th>
          <td mat-cell *matCellDef="let element">{{ element.cnae }} </td>
        </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5]"
      [pageSize]="sizeItems"
      [length]="totalPages"
      (page)="change($event)" 
      showFirstLastButtons></mat-paginator></div>
  </div>