import { Component, OnDestroy, Input, ElementRef, Optional, Self, OnInit, Output, EventEmitter, HostBinding, ChangeDetectorRef } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormBuilder, NgControl, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { tap, takeWhile } from 'rxjs/internal/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'fd-currency-input',
  templateUrl: 'fd-currency-input.component.html',
  styleUrls: ['fd-currency-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FdCurrencyInputComponent }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  }
})

export class FdCurrencyInputComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
  static nextId = 0;
  private readonly REQUIRED_VALIDATOR_KEY = 'required';
  alive = true;

  @Input()
  field: FdCurrencyInputConfig;

  @Output()
  input = new EventEmitter<string>();

  @Output()
  change = new EventEmitter<string>();

  @Input()
  parentForm: UntypedFormGroup;

  @Output()
  blur = new EventEmitter<string>();

  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    this.cdr.detectChanges();
    return this.hasError;
  }

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'fd-currency-input';
  describedBy = '';
  onChange = (_: any) => { };
  onTouched = () => { };

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl && this.relatedFormControl.errors != null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter(val => this.relatedFormControl.errors[val])
      .map(key => this.field.messages[key]);
  }


  get shouldLabelFloat() { return this.focused; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  constructor(
    formBuilder: UntypedFormBuilder,
    private currencyPipe: CurrencyPipe,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    super();

    focusMonitor.monitor(elementRef, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe(origin => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  unmask(control: AbstractControl, maskCharsReplace: RegExp): string {
    let unmaskedValue = '';
    if (!control) { return; }
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value) {
            unmaskedValue = value.replace(maskCharsReplace, '').trim();
            control.setValue(unmaskedValue, { emitEvent: false, emitModelToViewChange: false });
          }
        }))
      .pipe(takeWhile(() => this.alive))
      .subscribe();

    return unmaskedValue;
  }

  ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  async ngOnInit() {
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }

    if (!this.relatedFormControl || !this.relatedFormControl.value) {
      this.relatedFormControl.setValue(0);
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get required(): boolean {
    const validator = this.relatedFormControl.validator({} as AbstractControl);

    return validator && validator.required;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === '-' && !this.field.allowNegative) {
      event.preventDefault();
      event.stopPropagation();
    }

  }

  handleKeyUp(event: KeyboardEvent) {
    if (this.change) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.change.emit(inputValue);
    }
    event.stopPropagation();

  }

  handleFocus(event: Event) {
    const field = this.elementRef.nativeElement.querySelector('input');

    if (field && field.value) {
      setTimeout(() => {
        field.setSelectionRange(
          field.value.length,
          field.value.length,
        );
      }, 0);
    }
  }

  getMaxLength() {
    const value = this.relatedFormControl.value;

    if (!this.field || !value) {
      return;
    }

    if (!this.field.maxLength) {
      return 14;
    }

    return this.field && this.field.maxLength + 2;
  }

  handleBlur(event: Event): void {
    if (this.blur) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.blur.emit(inputValue.replace(/\D+/g, ''));
    }
    event.stopPropagation();
  }

  handleChange(event: Event): void {
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }

  getOptions() {
    if (this.field) {

      if (!this.field.isPercent && !this.field.isPercentNoDecimal) {
        return { prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' };
      }

      else if (this.field.isPercentNoDecimal) {
        return { prefix: '', suffix: ' %', thousands: '', decimal: '', align: 'left', precision: 0 };
      }

      else {
        return { prefix: '', suffix: ' %', thousands: '.', decimal: ',', align: 'left' };
      }
    }
  }

}


export type Mask = (RegExp | string)[];

export class FdCurrencyInputConfig {
  controlName: string;
  label: string;
  maxLength: number;
  isPercent: boolean;
  allowNegative?: boolean = false;
  disabled?: boolean;
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
  isPercentNoDecimal: boolean
}
