<div class="fd-accreditation-form__container">
    <div class="forms" fxLayout="column" fxLayoutAlign="center stretch">
        <h4 class="fd-subtitle">PRECIFICAÇÃO DE GRUPO COMERCIAL AZULZINHA</h4>
        <form *ngIf="!!formGroup && !!fields" [formGroup]="formGroup">
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxLayout="line" style="justify-content: space-between;">
                    <fd-input style="width: 49%;" [parentForm]="formGroup" [field]="fields.registration"> </fd-input>
                    <fd-input style="width: 49%;" [parentForm]="formGroup" [field]="fields.agencyNumber"> </fd-input>
                </div>

                <div fxLayout="line" style="justify-content: space-between;">
                    <fd-select
                    style="width: 49%;"
                        (loadItemsFn)="getMccList($event)"
                        [parentForm]="formGroup"
                        [field]="fields.mccNumber"
                        (selectionChange)="getCnaeListByMcc($event)"
                    >
                    </fd-select>
                    <fd-select style="width: 49%;" [parentForm]="formGroup" [field]="fields.state"> </fd-select>
                </div>

                <div fxLayout="line" style="justify-content: space-between;">
                    <fd-currency-input [parentForm]="formGroup" (change)="billingValuesChange($event)" [field]="fields.monthlyGroupBillingOnCard" style="width: 49%;"></fd-currency-input>
            
                    <fd-input  [parentForm]="formGroup" [field]="fields.annualBillingValue" style="width: 49%;"></fd-input >
                </div>

                <div>
                    <h4 class="fd-subtitle"> Deseja antecipar? </h4>
                    <fd-radio-button
                      [parentForm]="formGroup"
                      [field]="fields.isPrepayment">
                    </fd-radio-button>
                </div>

                <div style="margin-bottom: 2em;">
                    <h4 class="fd-subtitle"> Informe os CNPJs participantes do grupo </h4>
                </div>
                <div fxLayout="line" style="justify-content: space-between;">
                    <div formArrayName="companies" style="width: 60%;" >
                        <div  *ngFor="let company of companyListArray().controls; let i = index;">
                            <div [formGroupName]="i">
                                <div fxLayout="line" style="justify-content: space-between; margin: 10px 0;">
                                    <fd-input style="width: 27%" [parentForm]="company" [field]="fieldsCompany[i].cnpj" (input)="startEdit()" ></fd-input>

                                    <fd-select
                                        style="width: 28%"
                                        (loadItemsFn)="getCnaeList($event, i)"
                                        [parentForm]="company"
                                        [field]="fieldsCompany[i].cnaeNumber"
                                        (selectionChange)="startEdit()"
                                    >
                                    </fd-select>

                                    <div class="currency-component">
                                        <fd-currency-input [parentForm]="company" [field]="fieldsCompany[i].monthlyBillingOnCard" (change)="companyBillingValuesChange($event, i)" (blur)="startEditCurrency($event)"></fd-currency-input>
                                        <label *ngIf="showLabelAnnualBilling(i)" class="annual-billing-value">{{annualBillingValues[i]}}</label>
                                    </div>
                                    
                                    <div class="icon-trash">
                                        <fa-icon (click)="removeCompanyByButton(i)" icon="trash"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-evenly;">
                            <fd-button size="thin" fdTertiary [content]="addPartnerButtonHTML" (trigger)="addCompany('', '', '')" [disabled]="isUsingFile"> </fd-button>
                            <fd-button size="thin" fdSecondary [content]="removePartnerButtonHTML" (trigger)="clearListToFile()" [disabled]="isEnabledClearList"> </fd-button>
                        </div>
                    </div>
                    <div formArrayName="companies" style="width: 40%;" >
                        <div style="justify-content: center;">
                            <button class="fd-button" (click)="uploadCompanies()" matTooltip="Importar CNPJs em lote">
                                <div class="d-flex  justify-content-center">
                                  <fa-icon icon='user-lock' size="1x"></fa-icon> Importar
                                </div>
                              </button>
                            <div fxFlex="5" fxFlexAlign="center" style="text-align: center;">
                                <fa-icon class="fd-icon" icon='download' size="1x" (click)="downloadTemplate()"
                                    matTooltip="Download do modelo de arquivo">
                                </fa-icon>
                            </div>
                            <div fxFlex="5" fxFlexAlign="center" style="text-align: center;">
                                <fa-icon class="fd-icon" icon='trash' size="1x" (click)="removeFile()"
                                    matTooltip="Remover arquivo">
                                </fa-icon>
                            </div>
                        </div>
                        <div class="div_upload" #uploadCompany>
                            {{ fileName || "Selecione um arquivo."}}
                            <ngx-mat-file-input class="hiddenUpload" (change)="startUploadCompany($event)"
                            name="upload-start" method="uploadCompany" accept=".csv"></ngx-mat-file-input>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="fd-accreditation-form__container__actions">
        <fd-button
            class="no-print"
            [content]="'Voltar para o simulador de taxas'"
            (trigger)="backToSimulation()">
        </fd-button>
        <fd-button
            class="no-print"
            [content]="'solicitar precificação'"
            (trigger)="requestPricing()">
        </fd-button>
    </div>
</div>
