export class CnaeAndMccResponseDTO{
    mcc: string;
    cnaeNumber: string;
    merchantTariffDescription: string;
    cnaeDescription: string;
}

export class CnaeAndMccSearchTableDTO{
    mcc: string;
    cnae: string;
}