import { Component, Inject, OnInit } from '@angular/core';
import { CommercialConditionsHistoryDTO, ExemptionPolicyFeeDataModel, SimulationAdvanceFeeModel, SimulationPricingBrandHistoryDTO, SimulationTechnologyPricingHistoryDTO } from '../../simulation-economical-group/models/commercial-conditions-history.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EconomicGroupService } from 'src/app/order/services/external/economic-group/economic-group.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/order/messages/order.messages';
import { TableColumnsModel } from '../../simulation-data/simulation-table/models/table-columns.model';
import { BehaviorSubject } from 'rxjs';
import { getValueWithDecimalPlaces } from 'src/app/order/utils/decimal-places';
import { ExemptionPolicyDataModel } from '../../simulation-economical-group/models/economical-group-simulation.model';

@Component({
  selector: 'app-simulation-economical-group-pricing-detail',
  templateUrl: './simulation-economical-group-pricing-detail.component.html',
  styleUrls: ['./simulation-economical-group-pricing-detail.component.scss']
})
export class SimulationEconomicalGroupPricingDetailComponent implements OnInit {

  commercialConditionsHistoryDTO: CommercialConditionsHistoryDTO;
  
  private advanceFeeSubject = new BehaviorSubject<SimulationAdvanceFeeModel[]>(null);
  private pricingDataSource = new BehaviorSubject<SimulationPricingBrandHistoryDTO[]>(null);
  private technologyDataSource = new BehaviorSubject<SimulationTechnologyPricingHistoryDTO[]>(null);
  private exemptionPolicyFeesDataSource = new BehaviorSubject<ExemptionPolicyFeeDataModel[]>(null);

  advanceFeeProperties: TableColumnsModel[] = [
    { name: "annualBilling", columnTitle: "Faturamento/Mês", type: "currencyOrTax" },
    { name: "suggestedFee", columnTitle: "Taxa/Mês", type: "fee" },
  ];

  pricingDataSourceProperties: TableColumnsModel[] = [
    { name: "brandName", columnTitle: "Bandeira", type: "string" },
    { name: "creditFee", columnTitle: "Crédito", type: "fee" },
    { name: "debitFee", columnTitle: "Débito", type: "fee" },
    { name: "creditFeeLessSixInstallment", columnTitle: "Parcelamento em até 6x", type: "fee" },
    { name: "creditFeeGreaterSixInstallment", columnTitle: "Parcelamento mais de 6x", type: "fee" },
  ];

  technologyDataSourceProperties: TableColumnsModel[] = [
    { name: "name", columnTitle: "Tecnologia", type: "string" },
    { name: "rent", columnTitle: "Valor Mensal", type: "currencyOrTax" },
  ];

  policyFeesDataSourceProperties: TableColumnsModel[] = [
    { name: "monthBilling", columnTitle: "Faturamento Mês", type: "string" },
    { name: "discountPercent", columnTitle: "Isenção Aluguel", type: "percentage" },
    { name: "qtdEquipment", columnTitle: "# Máquinas", type: "string" },
  ];

  exemptionPolicyDescription: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public simulationCod: any,
    private EconomicGroupService: EconomicGroupService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<CommercialConditionsHistoryDTO>,
    ) { }

  ngOnInit(): void {
    this.getEconomicGroupSimulationPricingDetails();
  }

  getEconomicGroupSimulationPricingDetails(){
    this.EconomicGroupService.getEconomicGroupSimulationPricingDetail(this.simulationCod)
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(item => {
      this.commercialConditionsHistoryDTO = item;
      this.advanceFeeSubject.next([
        {
          annualBilling: item.annualCardBilling,
          suggestedFee: item.simulationPricingHistory.anticipationFee ? getValueWithDecimalPlaces(
            item.simulationPricingHistory.anticipationFee
          ) : '--',
        }]);
      this.pricingDataSource.next(item.simulationPricingHistory.simulationPricingBrands);
      this.technologyDataSource.next(item.simulationTechnologyPricingHistory);
      this.exemptionPolicyFeesDataSource.next(this.buildExemptionPolicyData(item.simulationExemptionPolicyFees));
    }, () => {
      this.dialogService.openDialog(Messages.ECONOMIC_GROUP_SIMULATION_DETAILS_ERROR);
      this.close();
    })
  }

  buildExemptionPolicyData(simulationExemptionPolicyHistory: ExemptionPolicyDataModel): ExemptionPolicyFeeDataModel[] {
    if (simulationExemptionPolicyHistory !== null && simulationExemptionPolicyHistory.exemptionFees !== null &&
      simulationExemptionPolicyHistory.exemptionFees.length > 0) {
      this.exemptionPolicyDescription = simulationExemptionPolicyHistory.description;
      let exemptionPolicyFeeDataTable: ExemptionPolicyFeeDataModel[] = [];
      simulationExemptionPolicyHistory.exemptionFees.forEach((item, index) => {
        let prefix = index === 0 ? 'De': 'Acima de';
        let monthBilling = item.maxRange ? `${prefix} R$ ${getValueWithDecimalPlaces(item.minRange)} até R$ ${getValueWithDecimalPlaces(item.maxRange)}`:
        `Acima de R$ ${getValueWithDecimalPlaces(item.minRange)}`
        
        exemptionPolicyFeeDataTable.push({
          monthBilling: monthBilling,
          discountPercent: item.discountPercent,
          qtdEquipment: item.qtdEquipment > 1 ? `Até ${item.qtdEquipment} Máquinas` : `${item.qtdEquipment} Máquina`
        })
      });
      return exemptionPolicyFeeDataTable;
    }
    return null;
  }


  public clear() {
    // Zerando estado dos componentes
    this.advanceFeeSubject.next(null);
    this.pricingDataSource.next(null);
    this.technologyDataSource.next(null);
    this.exemptionPolicyFeesDataSource.next(null);
  }

  close() {
    this.clear();
    this.dialogRef.close();
  }

  get advanceFee$() {
    return this.advanceFeeSubject.asObservable();
  }

  get pricingDataSource$() {
    return this.pricingDataSource.asObservable();
  }

  get technologyDataSource$() {
    return this.technologyDataSource.asObservable();
  }

  get exemptionPolicyFeesDataSource$() {
    return this.exemptionPolicyFeesDataSource.asObservable();
  }

}
