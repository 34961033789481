import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.local';
import { SimulationRequest } from 'src/app/shared/models/simulation-request.model';

@Injectable({
  providedIn: 'root'
})
export class GroupPricingService {

    constructor(private http: HttpClient) { }

    saveGroupPricing(simulationRequest: SimulationRequest){
      return this.http.post<SimulationRequest>(`${environment.apiUrls.comercialConditions}/simulation`, simulationRequest);
    }

}