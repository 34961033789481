import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { Messages } from 'src/app/order/messages/order.messages';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private dialogService: DialogService) { }

  handleXHRError(err: HttpErrorResponse, genericErrorMessage: Messages, callbackFn?: () => any, buttonText?: string) {
    console.error(err);
    const serializedError = err.error ? err.error as ApiResultModel : null;
    const mainError = err.error.mainError;

    if(mainError) {
      let message = new Messages("Atenção", mainError);
      this.dialogService.openDialogWithHtmlMessage(message);
      return;
    }

    if (serializedError && serializedError.message) {
      if(callbackFn){
        this.dialogService.openDialogWithMessage(serializedError.message, () => callbackFn(), buttonText);
        return;
      }
      this.dialogService.openDialogWithMessage(serializedError.message);
    } else {
      if(callbackFn){
        this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
        return;
      }
      this.dialogService.openDialog(genericErrorMessage);
    }
  }
}
