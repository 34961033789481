<div fxLayout="column">
    <div *ngIf="hasValue">
        <mat-table  matSort [dataSource]="dataSource">
    
            <ng-container matColumnDef="CNPJ" class="table__column">
                <mat-header-cell *matHeaderCellDef mat-sort-header>CNPJ</mat-header-cell>
                <mat-cell *matCellDef="let element"><b>{{element.cnpj}}</b></mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="Status do CNPJ" class="table__column">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status do CNPJ</mat-header-cell>
                <mat-cell *matCellDef="let element"><b>{{element.cnpjStatus}}</b></mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="Orientação" class="table__column"   >
                <mat-header-cell *matHeaderCellDef mat-sort-header>Orientação</mat-header-cell>
                <mat-cell *matCellDef="let element"><b>{{element.orientation}}</b></mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
        <mat-paginator *ngIf="hasValue"
         #paginator
         [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5]"
         [pageSize]="sizeItems"
         [length]="length"
         (page)="change($event)"
         showFirstLastButtons></mat-paginator>
    </div>
    <div *ngIf="!hasValue" fxLayout="row" fxLayoutAlign="center">
        <h5>Nenhum registro foi encontrado</h5>
    </div>
</div>