import { Component, Input, OnInit } from '@angular/core';
import { getValueWithDecimalPlaces} from 'src/app/order/utils/decimal-places';
import { TableColumnsModel } from './models/table-columns.model';

@Component({
  selector: 'app-simulation-table',
  templateUrl: './simulation-table.component.html',
  styleUrls: ['./simulation-table.component.scss']
})
export class SimulationTableComponent implements OnInit {

  @Input() public dataSource: any;
  @Input() public objectProperties: TableColumnsModel[] = [];
  @Input() public displayedColumns: string[] = [];
  @Input() public tableName: string = 'Taxas MDR';

  ngOnInit() {
    if (this.displayedColumns.length <= 0) {
      this.displayedColumns = this.objectProperties.map(o => o.name);
    }
  }

  public resolveValue(val, prop: TableColumnsModel) {
    if (prop.type === 'fee') {
      return this.handleFee(val, prop);
    } else if (prop.type === 'string') {
      return this.handleString(val, prop)
    }else if (prop.type === 'currencyOrTax'){
      return this.handleTax(val, prop);
    } else if (prop.type === 'percentage') {
      return val[prop.name] + '%';
    }
  }

  handleString(val, prop: TableColumnsModel) {
    if (val[prop.name]) {
      return val[prop.name];
    }
    return prop.name === 'flag'? 'Todas' : '';
  }

  handleFee(val, prop: TableColumnsModel) {
    if(this.isCurrency(val)){
      return val[prop.name];
    }
    if(val[prop.name] || !val[prop.name] && val[prop.name] == 0){
      if(getValueWithDecimalPlaces(val[prop.name]) == undefined){
        return "--";
      }
      return val[prop.name] || !val[prop.name] && val[prop.name] == 0  ? getValueWithDecimalPlaces(val[prop.name]) + ' %' : '';
    }

    return undefined;
  }

  handleTax(val, prop: TableColumnsModel) {
    if(this.isTax(val)){
      return val[prop.name];
    }
    return val[prop.name] || !val[prop.name] && val[prop.name] == 0  ? 'R$ ' + getValueWithDecimalPlaces(val[prop.name])  : '-';
  }

  private isCurrency(val){
    return val["type"] === "CURRENCY";
  }

  private isTax(val){
    return val["type"] === "TAX";
  }


}
