import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take} from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { PersonTypeEnum } from 'src/app/order/enums/person-type.enum';
import { cpfCnpjMask } from 'src/app/order/masks/document-masks';
import { Messages } from 'src/app/order/messages/order.messages';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { FeeService } from 'src/app/order/services/external/fee/fee.service'
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { sanitizeCpfCnpj } from 'src/app/order/utils/sanitize-search-filter';
import { stateList } from 'src/app/order/utils/state-list';
import { CpfCnpjValidator } from 'src/app/order/validators/cpf-cnpj-validator';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { ClientInformationModel } from 'src/app/shared/models/simulation.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss']
})
export class ClientInformationComponent implements OnInit {

  private readonly CEF_SERVICE_CONTRACT = '149';
  private readonly BILLING_VALUE_30M="30000000";

  @Input() clientInformation = new ClientInformationModel();
  @Output() consultOffers = new EventEmitter<ClientInformationModel>();
  @Output() cleanDown = new EventEmitter<ClientInformationModel>();

  formGroup: UntypedFormGroup = this.createFormGroup();
  fields: FdFieldConfigs = this.createFields();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cnaeService: CnaeService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected scrollService: ScrollService,
    protected matDialog: MatDialog,
    protected routingService: RoutingService,
    private route: ActivatedRoute,
    private dataStoreService: DataStoreService,
    private feeService: FeeService
  ) {
  }

  ngOnInit() {
    this.checkParams();
  }

  private readonly CPF_REGEX = /^\d{11}$/;
  private readonly CNPJ_REGEX = /^\d{14}$/;

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  get isCPF(): boolean {
    const document = sanitizeCpfCnpj(this.formControls.cpfCnpj.value);
    return document && document.length === 11 && this.CPF_REGEX.test(document);
  }

  private checkParams() {
      this.formControls.billingValue.setValue(this.clientInformation.billingValue);
      this.formControls.cpfCnpj.setValue(sessionStorage.getItem(Constants.CPF_CNPJ));
      this.formControls.state.setValue(sessionStorage.getItem(Constants.UF));
      let cnaeParam = sessionStorage.getItem(Constants.CNAE);
      this.getCnaeList(cnaeParam, true);
  }

  get isCNPJ(): boolean {
    const document = sanitizeCpfCnpj(this.formControls.cpfCnpj.value);
    return document && document.length === 14 && this.CNPJ_REGEX.test(document);
  }

  getCnaeList(filterValue: string, loadingPage: boolean) {

    // No caso de CNPJ, os CNAE's virão populados da tela anterior (consulta do Serpro).
    // update 06/2021 do item acima. Não mais. A Serpro será consultada na última tela, então o cnae será selecionado manualmente e validado posteriormente.
    if (!filterValue || !filterValue.length) {
      return;
    }

    if (filterValue.length < 3) {
      this.cnaeDropdown.items = [];
      return;
    }

    this.cnaeService.getCnaeList(filterValue)
      .pipe(take(1))
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          this.cnaeDropdown.items = [];
          this.cnaeDropdown.items.push(...cnaeList);
          this.isCnaeLoaded.next(true);
          let currentItem = this.cnaeDropdown.items.find((x) => x.value == filterValue);
          if (loadingPage && currentItem) {
            currentItem.selected = true;
            this.formControls.cnaeNumber.setValue(currentItem.value);
          }
        }
      },
      () => {
        this.cnaeDropdown.items = [];
      });
  }

  isCnaeLoaded: Subject<boolean> = new Subject();

  get cnaeDropdown() {
    return (this.fields.cnaeNumber as FdSelectConfig);
  }

  protected createFields() {
    return {
      cpfCnpj: {
        label: 'CPF/CNPJ',
        controlName: 'cpfCnpj',
        type: InputType.TEL,
        mask: cpfCnpjMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'informe o CPF/CNPJ',
          invalidCnpj: 'CNPJ inválido',
          invalidCpf: 'CPF inválido'
        }
      },
      state: {
        label: 'Estado (UF)',
        items: stateList,
        controlName: 'state',
        messages: {
          required: 'informe o estado',
          invalid: 'Selecione um estado'
        }
      },
      cnaeNumber: {
        label: 'CNAE',
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o CNAE',
        controlName: 'cnaeNumber',
        messages: {
          required: 'informe o CNAE',
          invalid: 'CNAE inválido'
        }
      },
      billingValue: {
        label: 'Informe faturamento anual no cartão',
        controlName: 'billingValue',
        messages: {
          required: 'informe o faturamento anual',
          invalid: 'faturamento inválido',
          min: 'faturamento inválido',
          max: 'faturamento inválido'
        }
      },
    };
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      cpfCnpj: [{value: '', disabled: true}, [Validators.required, CpfCnpjValidator]],
      cnaeNumber: [{value: '', disabled: true}, Validators.required],
      billingValue: ['', [Validators.required, Validators.min(0.01), Validators.max(999999999)]],
      state: [{value: '', disabled: true}, Validators.required]
    });
  }

  checkCnae() {
    return this.cnaeService.checkCnae(this.formGroup.controls['cnaeNumber'].value, this.CEF_SERVICE_CONTRACT).toPromise()
      .then(data => {
        if (!data) {
          this.dialogService.openDialogWithHtmlMessage(Messages.UNWANTED_CNAE);
        }
        return data;
      });
  }

  isBillingValueEqualsOrAboveThirtyMillions(){
    let billingValue = this.formGroup.controls['billingValue'].value;
    return (billingValue >= this.BILLING_VALUE_30M)
  }

  public async showFeeData() {
    if(this.isBillingValueEqualsOrAboveThirtyMillions()){
      this.dialogService.openDialogWithHtmlMessage(Messages.BILLING_VALUE_EQUALS_OR_ABOVE_THIRTY_MILLIONS);
      return;
    }

    const checkCnae = await this.checkCnae();

    if (!checkCnae) {
      return;
    }

    const personType = this.getPersonType()

    this.consultOffers.emit({ ...this.formGroup.getRawValue(), personType });

    this.cleanWhenFormValueChanges();
  }

  getPersonType() {
    if(this.isCNPJ) {
      return PersonTypeEnum.LEGAL
    }
    return this.isCPF ? PersonTypeEnum.PHYSICAL : '';
  }

  private cleanWhenFormValueChanges() {
    this.formGroup.valueChanges
      .pipe(take(1))
      .subscribe(() => {
        this.cleanDown.emit(null);
      })
  }

}
