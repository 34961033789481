import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Messages } from 'src/app/order/messages/order.messages';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { FdRadioButtonConfig } from 'src/app/shared/fd-form-components/fd-radio-button/fd-radio-button.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { DataStoreService } from '../../store/data-store.service';
import { FormBase } from '../form-base';
import { ScrollService } from '../../services/internal/scroll/scroll.service';
import { Router } from '@angular/router';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { cnpjMask } from '../../masks/document-masks';
import { CnpjValidator } from '../../validators/cpf-cnpj-validator';
import { SafeHtml } from '@angular/platform-browser';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { saveAs } from 'file-saver-es';
import { OnlyNumberMask } from '../../masks/only-number-mask';
import { CnaeValidateByMCC } from '../../validators/cnaes-mcc-validator';
import { Company, SimulationRequest } from 'src/app/shared/models/simulation-request.model';
import { GroupPricingService } from '../../services/external/group-pricing/group-pricing.service';
import { stateList } from '../../utils/state-list';
import { ClientInformation } from 'src/app/shared/side-nav/side-navs/side-nav.items';
import {ErrorService} from "../../services/internal/error/error.service";

@Component({
  selector: 'group-pricing',
  templateUrl: './group-pricing.component.html',
  styleUrls: ['./group-pricing.component.scss']
})
export class GroupPricingComponent extends FormBase implements OnInit {

    @ViewChild('uploadCompany') uploadCompany: ElementRef;

    formGroup: UntypedFormGroup = this.createFormGroup();
    fields: FdFieldConfigs = this.createFields();
    fieldsCompany: FdFieldConfigs[] = new Array<FdFieldConfigs>();
    fileName = '';
    companyListFile: Array<Company> = [];
    wrongCnaeList = [];
    cnaeListByMcc = [];
    annualBillingValues = [];
    usingFile: boolean = null;
    startEditFields: boolean = false;


    private readonly CEF_SERVICE_CONTRACT = '149';

    private readonly BILLING_VALUE_30K="30000";

    public optionsPrepayment: Item[] = [
      {
        label: 'Sim',
        value: true,
      },
      {
        label: 'Não',
        value: false,
        selected: true,
      },
    ];

    constructor(
      private formBuilder: UntypedFormBuilder,
      private cnaeService: CnaeService,
      protected dialogService: DialogService,
      protected scrollService: ScrollService,
      protected groupPricingService: GroupPricingService,
      private dataStorange: DataStoreService,
      private route: Router,
      private errorService: ErrorService,
    ) {
      super(scrollService);
    }

    ngOnInit() {
      this.formControls.registration.setValue(this.registrationCode());
      this.formControls.agencyNumber.setValue('');
      this.formControls.monthlyGroupBillingOnCard.setValue('');
      (this.fields.isPrepayment as FdRadioButtonConfig).items = this.optionsPrepayment;
      this.formControls.isPrepayment.setValue(false);
      this.fieldsCompany.push(
        this.createFieldsCompany(),
        this.createFieldsCompany(),
        this.createFieldsCompany(),
        this.createFieldsCompany(),
        this.createFieldsCompany(),
        this.createFieldsCompany());
    }

    protected createFormGroup() {
      return this.formBuilder.group({
        registration: [{value: '', disabled: true}, Validators.required],
        agencyNumber: [{value: ''}, Validators.required],
        mccNumber: ['', Validators.required],
        state: ['', Validators.required],
        monthlyGroupBillingOnCard: ['', [Validators.required, Validators.min(0.01), Validators.max(999999999)]],
        annualBillingValue: ['', [Validators.required]],
        isPrepayment: [{value: false}, Validators.required],
        companies: this.formBuilder.array([
          this.getCompanyFields('', '', ''),
          this.getCompanyFields('', '', ''),
          this.getCompanyFields('', '', ''),
          this.getCompanyFields('', '', ''),
          this.getCompanyFields('', '', ''),
          this.getCompanyFields('', '', '')])
      });
    }

    getCompanyFields(cnpj, cnaeNumber,  monthlyBillingOnCard) {
      return this.formBuilder.group({
        cnpj: [cnpj, [Validators.required, CnpjValidator]],
        cnaeNumber: [cnaeNumber, [Validators.required, CnaeValidateByMCC(this)]],
        monthlyBillingOnCard: [monthlyBillingOnCard, [Validators.required, Validators.min(0.01), Validators.max(999999999)]],
      });
    }

    showLabelAnnualBilling(index: string) {
      let companyControl = (this.formGroup.get('companies') as FormArray).controls[index]
      let billingControl = companyControl.get('monthlyBillingOnCard')

      return billingControl.valid
    }

    registrationCode(): string {
      let registrationCode = this.dataStorange.getRegisterUserCode();
      if(registrationCode=='null' || !registrationCode)
      {
        return '';
      }
      return registrationCode;
    }

    isMccEmpty(){
      if(!this.formGroup.getRawValue().mccNumber){
        this.dialogService.openDialogWithMessage("Preencha o campo MCC para validar o cnae corretamente.")
        return true;
      }
      return false;
    }

    getCnaeList(filterValue: string, elementIndex) {

      if (!filterValue || !filterValue.length) {
        return;
      }

      if(this.isMccEmpty()){
        return;
      }

      if (filterValue.length < 3) {
        this.cnaeDropdown(elementIndex).items = [];
        return;
      }

      this.cnaeService.getCnaeList(filterValue)
        .pipe(take(1))
        .subscribe(cnaeList => {
          if (cnaeList != null) {
            this.cnaeDropdown(elementIndex).items = [];
            this.cnaeDropdown(elementIndex).items.push(...cnaeList);
          }
        },
        () => {
          this.cnaeDropdown(elementIndex).items = [];
        });
    }

    cnaeDropdown(elementIndex: number) {
      return (this.fieldsCompany[elementIndex].cnaeNumber as FdSelectConfig);
    }

    getMccList(filterValue: string) {

      if (!filterValue || !filterValue.length) {
        return;
      }

      if (filterValue.length < 3) {
        this.mccDropdown.items = [];
        return;
      }

      this.cnaeService.getMccList(Number(filterValue))
        .pipe(take(1))
        .subscribe(mccList => {
          if (mccList != null) {
            this.mccDropdown.items = [];
            this.mccDropdown.items.push(...mccList);
          }
        },
        () => {
          this.mccDropdown.items = [];
        });
    }

    get mccDropdown() {
      return (this.fields.mccNumber as FdSelectConfig);
    }

    getCnaeListByMcc(event){
      let mcc = event.value;
      this.cnaeListByMcc = [];
      if(mcc){
        this.cnaeService.getCnaeListByMcc(mcc)
          .pipe(take(1))
          .subscribe(cnaeList => {
              if (cnaeList != null) {
                this.cnaeListByMcc = cnaeList;
              }
            },
            () => {
              this.cnaeListByMcc = [];
            });
      }

    }

    addCompany(cnpj, cnaeNumber, monthlyBillingOnCard){
      this.fieldsCompany.push(this.createFieldsCompany());
      this.companyListArray().push(this.getCompanyFields(cnpj, cnaeNumber, monthlyBillingOnCard));
    }

    clearListToFile(){
      this.companyListArray().clear();
      this.fieldsCompany = []

      for(let i = 0; i < 6; i++){
        this.addCompany('', '', '');
      }

      this.startEditFields = false;
    }

    startEdit(){
      this.startEditFields = true;
      this.usingFile = false;
    }

    startEditCurrency(value){
      if(value != "000"){
        this.startEdit();
      }
    }

    addCompanyFile(cnpj, cnaeNumber, monthlyBillingOnCard){
      this.companyListFile.push(new Company(cnpj, cnaeNumber, monthlyBillingOnCard));
    }

    removeCompanyByButton(i: number){
      this.removeCompany(i);
    }

    removeCompany(i: number){
      this.companyListArray().removeAt(i);
      this.fieldsCompany.splice(i, 1);
    }

    downloadTemplate(){
      let data = ['CNPJ;CNAE;Faturamento mensal no cartão individual'];

      let csvArray = data.join('\r\n');

      let blob = new Blob(["\ufeff",csvArray], {type: 'text/csv' })
      saveAs(blob, "Exemplo de Planilha - Precificação.csv");
    }

    startUploadCompany(event: any) {
      this.companyListFile = [];

      const filereader: FileReader = new FileReader();
      const selectedfile =  event.target.files[0];

      this.fileName = selectedfile.name;

      let extArquivo = selectedfile.name.split('.').pop();
      if(extArquivo != 'csv') {
        return;
      }

      filereader.readAsBinaryString(selectedfile);
      this.readCompanies(filereader);
    }

    readCompanies(filereader: FileReader) {
      let wrongCnaeList = [];

      filereader.onload = (event:any)=>{
        let csvContent = event.target.result;
        let csvRows = this.clearCsvContent(csvContent);

        for(let i of csvRows){

          let company = this.cleanValues(csvRows[i]);

          if(this.hasWrongCNPJ(company.cnpj)){
            return;
          }

          if(company.cnpj && company.cnae && company.monthlyGroupBillingOnCard){
            if(!this.cnaeListByMcc.some(e => e.cnaeNumber == company.cnae)){
              wrongCnaeList.push(company.cnae);
              }

            this.addCompanyFile(company.cnpj, company.cnae, company.monthlyGroupBillingOnCard);
          }
        }
          if(this.hasWrongCnaesImporting(wrongCnaeList)){
             return;
         }
        this.blockInputs();
     }
    }

    cleanValues(line:string) {
      let company = line.split(';');
      return {
        cnpj: company[0].replace('.', '').replace('.', '').replace('/', '').replace('-', ''),
        cnae: parseInt(company[1].replace('.', '').replace('-', '')),
        monthlyGroupBillingOnCard: parseFloat(company[2].replace('R', '').replace('$', '').replace(' ', '').split('.').join('').replace(',', '.'))
      };
    }

    hasWrongCNPJ(cnpj){
      if (cnpj.length === 13) {
        cnpj = cnpj.padStart(14, '0');
      }
      if(cnpj.length !== 14){
        this.removeFile();
        this.dialogService.openDialogWithMessage("CNPJ invalido na planilha.");
        return true;
      }
      return false;
    }

    hasWrongCnaesImporting(wrongCnaeList){
      let uniq = [...new Set(wrongCnaeList)];
      if(uniq.length > 0){
        this.removeFile();
        if(uniq.length > 1){
          this.dialogService.openDialogWithHtmlMessage(new Messages(
            'Solicitação não permitida',
            `CNAEs ${wrongCnaeList} não Correspondem ao MCC informado.<br/><br/>\
            Consulte os Ramos e CNAEs correspondentes e tente novamente.`));
            return true;
        }
        this.dialogService.openDialogWithHtmlMessage(new Messages(
          'Solicitação não permitida',
          `CNAE ${wrongCnaeList} não Corresponde ao MCC informado.<br/><br/>\
          Consulte os Ramos e CNAEs correspondentes e tente novamente.`));
        return true;
      }
      return false;
    }

    blockInputs() {
      let companyListLength = this.companyListArray().controls.length;
      for(let i = 0; i < companyListLength; i++){
        this.companyListArray().controls[i].disable();
      }
      this.usingFile = true;
    }

    clearUpInputs() {
      let companyListLength = this.companyListArray().controls.length;
      for(let i = 0; i < companyListLength; i++){
        this.companyListArray().controls[i].enable();
      }
      this.usingFile = false;
    }

    clearCsvContent(csvContent: any) : string[] {

      let csvRows = csvContent.split('\r\n');

      csvRows.splice(0, 1);

      if(!csvRows[csvRows.length - 1]){
        csvRows.splice(csvRows.length - 1, 1);
      }
      return csvRows;
    }

    isBillingValueLessThanOrEqual30k(){
      let billingValue = this.formGroup.controls['monthlyGroupBillingOnCard'].value;
      return (billingValue <= this.BILLING_VALUE_30K)
    }

    requestPricing(){
      if(this.isBillingValueLessThanOrEqual30k()){
        this.dialogService.openDialogWithHtmlMessage(Messages.BILLING_VALUE_LESS_THAN_OR_EQUALS_30K);
        return;
      }

      let simulationRequest = new SimulationRequest();
      let rawValue = this.formGroup.getRawValue();

      simulationRequest.popValues(
        rawValue.registration, parseInt(rawValue.agencyNumber),
        rawValue.mccNumber, rawValue.state,
        rawValue.monthlyGroupBillingOnCard, rawValue.isPrepayment
      )

      if (this.isUsingFile) {
        simulationRequest.participants = this.companyListFile;
      } else {
        simulationRequest.participants = rawValue.companies.map(value =>({
          cnpj: value.cnpj,
          cnae: value.cnaeNumber,
          monthlyCardBilling: value.monthlyBillingOnCard
        }));
      }

      if(!simulationRequest.registrationManager){
        this.dialogService.openDialogWithMessage('Realize o salto logado para adicionar sua matricula!');
        return;
      }

      if(!simulationRequest.participants || simulationRequest.participants.length == 0){
        this.dialogService.openDialogWithMessage('Dados dos participantes do grupo não informado!');
        return;
      }

      if(!this.isFormGroupValid()){
        return;
      }

      this.groupPricingService.saveGroupPricing(simulationRequest).toPromise().then(
        (response) => {
          this.dialogService.openDialogWithHtmlMessage(
            new Messages('Precificação solicitada com sucesso', `Aguarde retorno no seu email ou consulte o status desta solicitação no menu "Consulta de Grupos Comerciais Azulzinha" em 1 dia útil.<br/>\
                          ID Precificação:  ${response}`),
            () => this.cleaningScreen()
            );
        },
        (error) => {
          if (error) {
            this.errorService.handleXHRError(error, Messages.GENERAL_ERROR)
          }
        }
      ).catch((error) => {
        let errorArg = error;
        if (error.error && typeof error.error === 'object') {
          errorArg = error.error;
          if (error.error instanceof ProgressEvent) {
            errorArg = error.message;
          }
        }
        this.dialogService.openErrorDialog(Messages.GROUP_PRICE_SAVE_ERROR, errorArg);
      });
    }

    billingValuesChange(value) {
      if(value) {
        let number = value.replace(/[^0-9,-]+/g,"")
        number = Number(number.replace(',', '.'))
        if(number) {
          number *= 12;
          this.formGroup.get('annualBillingValue').setValue(number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
        }
      }
    }

    companyBillingValuesChange(value, index) {
      if(value) {
        let number = value.replace(/[^0-9,-]+/g,"")
        number = Number(number.replace(',', '.'))
        if(number) {
          number *= 12;
          this.annualBillingValues[index] = 'Faturamento anual: ' +  number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
      }
    }

    cleaningScreen(){
      window.location.reload();
    }

    uploadCompanies() {
      if(this.startEditFields){
        this.dialogService.openDialogWithMessage("É necessario limpar a lista para usar a importação por arquivo.")
        return;
      }
      if(this.isMccEmpty()){
        return;
      }
      const el = this.uploadCompany.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
      el.value = "";
      el.click();
    }

    removeFile(){
      const el = this.uploadCompany.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
      el.value = "";
      this.fileName = "";
      this.companyListFile = [];
      this.clearUpInputs();
    }

    protected createFieldsCompany() : FdFieldConfigs{
      return {
        cnpj: {
          label: 'CNPJ',
          controlName: 'cnpj',
          type: InputType.TEL,
          mask: cnpjMask,
          isNumber: true,
          maskCharsReplace: /[.\/ -]/g,
          messages: {
            required: 'informe o CNPJ',
            invalidCnpj: 'CNPJ inválido'
          }
        },
        cnaeNumber: {
          label: 'CNAE',
          items: [],
          searchable: true,
          searchPlaceholder: 'Insira o CNAE',
          controlName: 'cnaeNumber',
          messages: {
            required: 'informe o CNAE',
            invalid: 'CNAE inválido',
            invalidCnae: 'CNAE não corresponde ao MCC informado'
          }
        },
        monthlyBillingOnCard: {
          label: 'Faturamento mensal no cartão',
          controlName: 'monthlyBillingOnCard',
          messages: {
            required: 'Informe o faturamento mensal',
            invalid: 'Faturamento inválido',
            min: 'Faturamento inválido',
            max: 'Faturamento inválido'
          }
        },
      }
    }

    protected createFields() {
      return {
        registration: {
          label: 'Matrícula',
          controlName: 'registration',
          messages: {
            required: 'Informe o número de matrícula',
            invalid: 'Informe o número de matrícula'
          }
        },
        agencyNumber: {
          label: 'Número da agência',
          controlName: 'agencyNumber',
          maxLength: 4,
          mask: OnlyNumberMask,
          messages: {
            required: 'Informe o número da agência',
            invalid: 'Informe o número da agência'
          }
        },
        mccNumber: {
          label: 'Informe o MCC',
          items: [],
          searchable: true,
          searchPlaceholder: 'Insira o MCC',
          controlName: 'mccNumber',
          messages: {
            required: 'informe o MCC',
            invalid: 'MCC inválido'
          }
        },
        state: {
          label: 'Estado (UF)',
          items: stateList,
          controlName: 'state',
          messages: {
            required: 'informe o estado',
            invalid: 'Selecione um estado'
          }
        },
        monthlyGroupBillingOnCard: {
          label: 'Faturamento mensal do grupo no cartão',
          controlName: 'monthlyGroupBillingOnCard',
          messages: {
            required: 'Informe o faturamento mensal do grupo no cartão',
            invalid: 'Faturamento inválido',
            min: 'Faturamento inválido',
            max: 'Faturamento inválido'
          }
        },
        annualBillingValue: {
          label: 'Faturamento anual do grupo no cartão',
          controlName: 'annualBillingValue',
          disabled: true,
          messages: {}
        },
        isPrepayment: {
          items: this.optionsPrepayment,
          controlName: 'isPrepayment',
          messages: {
            required: 'Informe o tipo de antecipação',
            invalid: 'Informe o tipo de antecipação',
          },
        }
      };
    }

    backToSimulation(){
      this.route.navigate([ClientInformation.link]);
    }

    companyListArray(){
      return this.formControls.companies as UntypedFormArray;
    }

    private renderPlusIcon(): SafeHtml {
      const iconDefinition = icon({ prefix: 'fas', iconName: 'plus-circle' });
      return iconDefinition.html;
    }

    private renderCloseIcon(): SafeHtml {
      const iconDefinition = icon({ prefix: 'fas', iconName: 'times' });
      return iconDefinition.html;
    }

    get formControls(): { [key: string]: AbstractControl } {
      return this.formGroup.controls;
    }

    get addPartnerButtonHTML() {
      return this.renderPlusIcon() + ' <span>adicionar CNPJ</span>';
    }

    get removePartnerButtonHTML() {
      return this.renderCloseIcon() + ' <span>Limpar lista</span>';
    }

    get isUsingFile(){
      if (this.usingFile == null) {
        return false;
      } else {
        return this.usingFile;
      }
    }

    get isEnabledClearList(){
      return !this.startEditFields;
    }

}
