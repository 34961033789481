import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import { FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';

import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxCurrencyModule} from 'ngx-currency';
import {
  FdRadioButtonComponent,
  FdRadioButtonConfig
} from './fd-form-components/fd-radio-button/fd-radio-button.component';

import {
  FdCurrencyInputComponent,
  FdCurrencyInputConfig
} from './fd-form-components/fd-currency-input/fd-currency-input.component';
import {FdInputComponent, FdInputConfig} from './fd-form-components/fd-input/fd-input.component';
import {FdSelectComponent, FdSelectConfig} from './fd-form-components/fd-select/fd-select.component';
import {FdButtonComponent} from './fd-form-components/fd-button/fd-button.component';
import {HeaderComponent} from './header/header.component';
import {BrowserModule} from '@angular/platform-browser';
import {FdConfirmComponent} from './fd-form-components/fd-confirm/fd-confirm.component';
import {FinishComponent} from '../order/components/simulation-data/finish/finish.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { VersionComponent } from './version/version.component';
import { ClientInformationSideNavComponent } from './side-nav/side-navs/client-information-side-nav/client-information-side-nav.component';
import { SidenavContentAreaDirective } from './side-nav/side-nav-content-area.directive';
import { DefaultSideNavComponent } from './side-nav/side-navs/default-side-nav.component';
import { EconomicGroupSideNavComponent } from './side-nav/side-navs/economic-group-side-nav/economic-group-side-nav.component';
import { IconsModule } from './icons.module';
import { TextMaskModule } from 'angular2-text-mask';

export type FdFieldConfig =
  FdInputConfig |
  FdSelectConfig |
  FdRadioButtonConfig |
  FdCurrencyInputConfig;

export interface FdFieldConfigs {
  [key: string]: FdFieldConfig;
}

@NgModule({
    declarations: [
        FdInputComponent,
        FdSelectComponent,
        FdButtonComponent,
        FdRadioButtonComponent,
        FdCurrencyInputComponent,
        HeaderComponent,
        FdConfirmComponent,
        FinishComponent,
        SideNavComponent,
        SidenavContentAreaDirective,
        DefaultSideNavComponent,
        ClientInformationSideNavComponent,
        EconomicGroupSideNavComponent,
        VersionComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        MatInputModule,
        MatIconModule,
        MatOptionModule,
        MatMenuModule,
        MatSelectModule,
        MatDialogModule,
        MatRadioModule,
        MatCheckboxModule,
        MatTableModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        TextMaskModule,
        MatSelectInfiniteScrollModule,
        NgxCurrencyModule,
        MatToolbarModule,
        RouterModule,
        IconsModule
    ],
    exports: [
        FdInputComponent,
        FdButtonComponent,
        FdSelectComponent,
        FdRadioButtonComponent,
        FdCurrencyInputComponent,
        HeaderComponent,
        FdConfirmComponent,
        SideNavComponent,
        VersionComponent
    ],
    providers: [
        MatDatepickerModule,
        MatTooltipModule,
        DatePipe,
        DecimalPipe
    ]
})

export class SharedComponentsModule {}
