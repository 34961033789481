const BLOCK_2: RegExp[] = [/\d/, /\d/];
const BLOCK_3: RegExp[] = [/\d/, /\d/, /\d/];
const BLOCK_4: RegExp[] = [/\d/, /\d/, /\d/, /\d/];

const CPF_LENGTH: number = 11;

export const cpfMask: (RegExp | string)[] = [
  ...BLOCK_3, '.', ...BLOCK_3, '.', ...BLOCK_3, '-', ...BLOCK_2
];

export const cnpjMask: (RegExp | string)[] = [
  ...BLOCK_2, '.', ...BLOCK_3, '.', ...BLOCK_3, '/', ...BLOCK_4, '-', ...BLOCK_2
];

export function cpfCnpjMask(userInput: string): (RegExp | string)[] {
  const numbers: string = (userInput || '').replace(/\D/g, '');
  return numbers.length <= CPF_LENGTH ? cpfMask : cnpjMask;
}
