import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuEconomicGroupItems } from '../side-nav.items';

@Component({
  selector: 'side-nav',
  templateUrl: './economic-group-side-nav.component.html',
  styleUrls: ['./economic-group-side-nav.scss']
})
export class EconomicGroupSideNavComponent {
  navItems = MenuEconomicGroupItems;

  constructor(
    private route: Router
  ) { }

  navigate(link: string): void{
    this.route.navigate([link]);
  }
  
}