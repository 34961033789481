<nav mat-tab-nav-bar class="fd-accreditation-side-nav">
    <ul>
        <li *ngFor="let item of navItems" class="fd-accreditation-side-nav__nav-li">
            <a mat-tab-link class="fd-accreditation-side-nav__nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" (click)="navigate(item.link)">
            <fa-icon [icon]="item.icon"></fa-icon>
            {{item.text}}
            </a>
        </li>
    </ul>
</nav>  