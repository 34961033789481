import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { RateDataDTO } from '../../simulation-economical-group/models/economical-group-simulation.model';
import { DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-simulation-table-flag',
  templateUrl: './simulation-table-flag.component.html',
  styleUrls: ['./simulation-table-flag.component.scss']
})
export class SimulationTableFlagComponent implements OnChanges{
  @Input() data: RateDataDTO[];
  @Input() tableName: string;
  uniqueFlags: string[] = [];
  modalities: string[] = [];
  columns: string[] = [];
  organizedData: any[] = [];
  dataSource:MatTableDataSource<RateDataDTO> = new MatTableDataSource();

  public displayedColumns = ['flagName', 'debit', 'credit', 'lessTo6Installments', 'more6Installments', 'installmentPlan'];

  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.organizeData();
  }

  ngOnChanges(changes: SimpleChanges) {
      this.organizeData();
  }

  private organizeData() {
    this.dataSource.data =  this.data;
  }

  public formatPercentage(number: number) {
    let numberFormatted = this.decimalPipe.transform(number, '1.2-2', 'pt');

    if(numberFormatted != null && numberFormatted != '0') {
      return numberFormatted.toString().concat(' %');
    }

    return '--';
  }

  public getFlagImageURL(flag: string): string {
    const flagLowerCase = flag.toLowerCase();

    return `../assets/images/table-flags/${flagLowerCase}.png`;
  }
}
