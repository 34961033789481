export class TechnologyInformationDto{
    tradeName: string;
    suggestedTechPrice: number;
    serviceId: string;
    amount: number;
    totalValue:number;
}

export class TechnologyInformationResponseDTO{
    techs: Array<TechnologyInformationDto>
    amountList: Array<number>
}

export class TechnologyInformationTableDto{
    tradeName: string;
    suggestedTechPrice: string;
    totalValue:string;
}

export class TechEventDTO{
    techInfo: TechnologyInformationDto;
    techTableInfo:TechnologyInformationTableDto;
}