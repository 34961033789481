
<div class="fd-business-data-detail__card-container width35" *ngIf="data">
    <table mat-table [dataSource]="dataSource" class="fd-table" aria-label="Table containing exemption policies">

        <!-- Faturamento Column -->
        <ng-container matColumnDef="faturamentoMes" class="fd-table__cell">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Faturamento Mês</th>
        <td mat-cell *matCellDef="let rowElement">{{ rowElement.maxRange ?
            "De " + (rowElement.minRange | currency: 'BRL') + " até " + (rowElement.maxRange | currency: 'BRL')  :
            "Acima de " +  (rowElement.minRange | currency: 'BRL') }}</td>
        </ng-container>

        <!-- Taxa Insenção Column -->
        <ng-container matColumnDef="isencaoAluguel" class="fd-table__cell">
        <th mat-header-cell *matHeaderCellDef  class="fd-table__header align-center">Isenção</th>
        <td class="align-center" mat-cell *matCellDef="let rowElement">{{ formatPercentage(rowElement.discountPercent) }}</td>
        </ng-container>

        <!-- Quantidade de Maquinas Column -->
        <ng-container matColumnDef="maquinas" class="fd-table__cell">
            <th mat-header-cell *matHeaderCellDef class="fd-table__header align-center"># Máquinas</th>
            <td class="align-center" mat-cell *matCellDef="let rowElement">{{ rowElement.qtdEquipment == 1 ?
                rowElement.qtdEquipment + " Máquina" :
                rowElement.qtdEquipment + " Máquinas"  }}</td>
        </ng-container>

        <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="item-text" *ngIf="description">
        <span class="fd-text">* {{description}}</span>
    </div>
</div>
