import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {TechnologyPriceDataModel} from "../models/economical-group-simulation.model";

@Component({
  selector: 'app-technology-price-table',
  templateUrl: './technology-price-table.component.html',
  styleUrls: ['./technology-price-table.component.scss'],
})
export class TechnologyPriceTableComponent implements OnChanges {
  @Input()
  public data: TechnologyPriceDataModel[];

  public dataSource: MatTableDataSource<TechnologyPriceDataModel> = new MatTableDataSource();
  public displayedColumns = ['tipoTerminalDesc', 'valor'];

  public ngOnInit() {
    this.dataSource.data = this.data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.dataSource.data = this.data;
    }
  }
}
