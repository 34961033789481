import { Component } from "@angular/core";
import { ClientInformationModel, SimulationModel } from "src/app/shared/models/simulation.model";
import { BehaviorSubject } from "rxjs";
import { TableColumnsModel } from "../simulation-data/simulation-table/models/table-columns.model";
import { OfferQueryDataModel } from "../simulation-data/fees-data/models/offer-query-data.model";
import {
  EconomicGroupClientInformationModel,
  EconomicalGroupSimulationRequestModel, RateDataDTO, PrepaymentDataModel, TechnologyPriceDataModel, ExemptionFeeDataModel
} from "./models/economical-group-simulation.model";
import { EconomicGroupService } from "../../services/external/economic-group/economic-group.service";
import { AdvanceFeeModel } from "../simulation-data/simulation-table/models/advance-fee.model";
import { getValueWithDecimalPlaces, getValueWithDecimalPlacesBrazillianFormat } from "../../utils/decimal-places";
import { ErrorService } from "../../services/internal/error/error.service";
import { Messages } from "../../messages/order.messages";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-simulation-economical-group',
  templateUrl: './simulation-economical-group.component.html',
  styleUrls: ['./simulation-economical-group.component.scss']
})
export class SimulationEconomicalGroup{

  public proposal: SimulationModel = {
    clientInformation: new ClientInformationModel(),
  };

  public offerQueryData = { campaignId: 0 } as OfferQueryDataModel;

  public viewControl = {
    clientInformation: true,
    offerTableData: false,
    advanceFeeTableData: false,
  };

  private genericMdrFeeSubject = new BehaviorSubject<RateDataDTO[]>(
    null
  );

  private advanceFeeSubject = new BehaviorSubject<AdvanceFeeModel[]>(null);

  advanceFeeProperties: TableColumnsModel[] = [
    {name: 'annualBilling', columnTitle: 'Faturamento/Mensal', type: 'string'},
    {name: 'suggestedFee', columnTitle: 'Taxa/Mês', type: 'fee'},
  ];

  prepaymentData: PrepaymentDataModel[] = [];
  technologyData: TechnologyPriceDataModel[] = [];
  exemptionPolicyFeesData: ExemptionFeeDataModel[] = [];
  description: string = '';
  rateData: RateDataDTO[] = [];

  constructor(
    private economicGroupService: EconomicGroupService,
    private errorService: ErrorService,
    private route: Router
  ) {}

  public clear() {
    this.viewControl.offerTableData = false;
    this.viewControl.advanceFeeTableData = false;
    this.proposal.clientInformation = null;
    this.genericMdrFeeSubject.next(null);
    this.advanceFeeSubject.next(null);
  }

  public showFeesData(clientInformation: EconomicGroupClientInformationModel) {
    let params = new EconomicalGroupSimulationRequestModel();
    params.annualBilling = clientInformation.billingValue;
    params.mccNumber = clientInformation.mccNumber;
    params.uf = clientInformation.state;
    params.prepayment = clientInformation.isPrepayment;

    this.prepaymentData = undefined;

    this.economicGroupService.getSimulation(params)
    .subscribe((res) => {
      this.viewControl.offerTableData = true;
      this.genericMdrFeeSubject.next(res.rateData);
      this.rateData = res.rateData;
      this.technologyData = res.technologies;
      this.exemptionPolicyFeesData = res.exemptionPolicy.exemptionFees;
      this.description = res.exemptionPolicy.description;
      if(clientInformation.isPrepayment) {
        this.prepaymentData = [res.prepaymentFees];
        this.viewControl.advanceFeeTableData = true;
        this.advanceFeeSubject.next([
          {
            annualBilling: getValueWithDecimalPlacesBrazillianFormat(res.prepaymentFees.annualBilling),
            suggestedFee: getValueWithDecimalPlaces(res.prepaymentFees.suggestedAutoPrepayment)
          }
        ])
      }
    },
    (error: HttpErrorResponse) => {
      this.handleException(error);
    });
  }

  public isEmptyPrePayment() {
    return !this.prepaymentData || this.prepaymentData.length === 0;
  }

  public isEmptyTechnology() {
    return !this.technologyData || this.technologyData.length === 0;
  }

  public isEmptyExemptionPolicyFees() {
    return !this.exemptionPolicyFeesData || this.exemptionPolicyFeesData.length === 0;
  }

  public isEmptyRates() {
    return !this.rateData || this.rateData.length === 0;
  }

  initialPage() {
    this.route.navigateByUrl("/simulation/client-information");
  }

  newPrecification() {
    this.route.navigateByUrl("/simulation/group-pricing");
  }

  private handleException(error) {
    console.log(error);
    switch (error.status){
      case HttpStatusCode.BadRequest: {
        this.errorService.handleXHRError(error, Messages.CLIENT_VALIDATION_ERROR);
        break;
      }
      case HttpStatusCode.InternalServerError: {
        this.errorService.handleXHRError(error, Messages.FAILED_TO_CALCULATE_FEES);
        break;
      }
      default: {
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
        break;
      }
    }
  }

  get genericMdrFee$() {
    return this.genericMdrFeeSubject.asObservable();
  }

  get advanceFee$() {
    return this.advanceFeeSubject.asObservable();
  }
}
