<div *ngIf="offerQueryData" style="margin-bottom: 30px">
  <h4 class="fd-subtitle">Aluguel</h4>

  <div fxLayout="line" style="justify-content: start;">
    <fd-select style="width: 29%;"
      (loadItemsFn)="filterTechnologyDropdownItems($event)"
      [parentForm]="formGroup"
      [field]="fields.techs" (selectionChange)="onChangeTech($event)"
    >
    </fd-select>
    <fd-select style="width: 29%; padding-left: 2%;"
      (loadItemsFn)="filterAmountDropdownItems($event)"
      [parentForm]="formGroup"
      [field]="fields.amount" (selectionChange)="onChangeAmount($event)"
    >
    </fd-select>
  </div>

  <div>
    <table *ngIf="showTable" mat-table [dataSource]="dataSourceTable" class="fd-table" style="width: 100%" aria-label="List of selected technologies">
      <ng-container matColumnDef="tradeName"  class="fd-table__cell">
        <th class="fd-table__header" mat-header-cell *matHeaderCellDef mat-sort-header>Tecnologia</th>
        <td mat-cell *matCellDef="let element"> {{ element.tradeName }} </td>
      </ng-container>
      <ng-container matColumnDef="unitValue"  class="fd-table__cell">
        <th class="fd-table__header" mat-header-cell *matHeaderCellDef mat-sort-header>Valor unitário mensal</th>
        <td mat-cell *matCellDef="let element">R$ {{ element.suggestedTechPrice }} </td>
      </ng-container>
      <ng-container matColumnDef="fullValue"  class="fd-table__cell">
        <th class="fd-table__header" mat-header-cell *matHeaderCellDef mat-sort-header>Valor total mensal</th>
        <td mat-cell *matCellDef="let element">R$  {{ element.totalValue }} </td>
      </ng-container>

    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row  class="fd-table__row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table >
  </div>
</div>

  <div *ngIf="offerQueryData.phrases && offerQueryData.phrases.pricingTechnologyPhrase" class="cef-disclaimer no-margin-bottom">
    {{ offerQueryData.phrases.pricingTechnologyPhrase }}
  </div>

  <div style="white-space:pre-wrap" *ngIf="offerQueryData.phrases && offerQueryData.phrases.exemptionPolicyPhraseFactorFlex" class="cef-disclaimer">
    <span [innerHTML]="offerQueryData.phrases.exemptionPolicyPhraseFactorFlex" ></span><br />
  </div>

  <div style="white-space:pre-wrap" *ngIf="offerQueryData.phrases && offerQueryData.phrases.phrase" class="cef-disclaimer">
    <span [innerHTML]="offerQueryData.phrases.phrase" ></span><br />
  </div>

  <div class="cef-disclaimer">
    <span [innerHTML]="offerQueryData.phrases.disclaimerTechPhrase" ></span><br />
  </div>

  <div class="cef-disclaimer no-margin-top">
    <div *ngIf="offerQueryData">
      <span [innerHTML]="offerQueryData.phrases.offerMessage" ></span><br />
    </div>
  </div>

