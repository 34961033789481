import {
  faSearchDollar, faUsers,faBars, faSearch
} from '@fortawesome/free-solid-svg-icons';

export const ClientInformation = {
  text: 'Voltar para Simulador de Taxas',
  link: '/simulation/client-information',
  mainMenuLinkRole: [],
  allowedRoles: [],
  icon: faUsers,
  hasOptions: true,
  optionsName: '',
  options: [],
  path: 'client-information'
};

export const FirstPageEconomicGroup = {
  text: 'Grupo Comercial Azulzinha',
  link: 'simulation/commercial-group',
  mainMenuLinkRole: [],
  allowedRoles: [],
  icon: faUsers,
  hasOptions: true,
  optionsName: '',
  options: [],
  path: 'commercial-group'
};

const simulationGroup = {
  text: 'Simulador de Taxas Grupo Comercial Azulzinha',
  link: 'simulation/commercial-group',
  mainMenuLinkRole: [],
  allowedRoles: [],
  icon: faSearchDollar,
  hasOptions: true,
  optionsName: '',
  options: [],
  path: 'commercial-group'
};

const simulationSearch = {
  text: 'Consulta de Grupos Comerciais Azulzinha',
  link: 'simulation/simulation-search',
  mainMenuLinkRole: [],
  allowedRoles: [],
  icon: faBars,
  hasOptions: true,
  optionsName: '',
  options: [],
  path: 'simulation-search'
};

const groupPricing = {
  text: 'Precificação de Grupo Comercial Azulzinha',
  link: '/simulation/group-pricing',
  mainMenuLinkRole: [],
  allowedRoles: [],
  icon: faUsers,
  hasOptions: true,
  optionsName: '',
  options: [],
  path: 'group-pricing'
};

const cnaeAndMccSearch = {
  text: 'Busca de CNAEs e MCC',
  link: 'simulation/cnae-and-mcc-search',
  mainMenuLinkRole: [],
  allowedRoles: [],
  icon: faSearch,
  hasOptions: true,
  optionsName: '',
  options: [],
  path: 'cnae-and-mcc-search'
};

export const EconomicGroupItems = [
  simulationGroup, groupPricing, simulationSearch,cnaeAndMccSearch
];

export const MenuEconomicGroupItems = [
  ClientInformation, ...EconomicGroupItems
]
