export class SimulationRequest {

    registrationManager: string;
    bankAgency: number;
    mcc: number;
    uf: string;
    monthlyGroupCardBilling: number;
    hasAnticipation: boolean;
    participants: Company[];

    /**
     *
     */
    constructor() {
        this.registrationManager = '';
        this.bankAgency = 0;
        this.mcc = 0;
        this.monthlyGroupCardBilling = 0;
        this.hasAnticipation = false;
        this.participants = [];
    }

    popValues(registration, agencyNumber, mcc, uf, monthlyGroupBillingOnCard, isPrepayment){
        this.registrationManager = registration;
        this.bankAgency = agencyNumber;
        this.mcc = mcc;
        this.uf = uf;
        this.monthlyGroupCardBilling = monthlyGroupBillingOnCard;
        this.hasAnticipation =  isPrepayment;
    }

}

export class Company {
    cnpj: string;
    cnae: number;
    monthlyCardBilling: number;

    constructor(cnpj: string, cnae: number,  monthlyCardBilling: number) {
        this.cnpj = cnpj;
        this.cnae = cnae;
        this.monthlyCardBilling = monthlyCardBilling;
    }
}