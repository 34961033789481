import { CnaeService } from "src/app/order/services/external/cnae/cnae.service";
import { HttpEventType } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, combineLatest } from "rxjs";
import { FactorFeeModel } from "../shared/models/response/response-factor-fee.model";
import {
  CampaignSimulationModel,
  ClientInformationModel,
  SimulationInformationModel,
  SimulationModel
} from "../shared/models/simulation.model";
import { OfferQueryDataModel } from "./components/simulation-data/fees-data/models/offer-query-data.model";
import { ParamRates, TechReportDTO } from "./components/simulation-data/fees-data/models/param";
import { AdvanceFeeModel } from "./components/simulation-data/simulation-table/models/advance-fee.model";
import { GenericMdrFeeModel } from "./components/simulation-data/simulation-table/models/generic-mdr-fee.model";
import { TableColumnsModel } from "./components/simulation-data/simulation-table/models/table-columns.model";
import { Constants } from "./constants/constants";
import { FeeTypeEnum } from "./enums/fee-type.enum";
import { Messages } from "./messages/order.messages";
import { FeeService } from "./services/external/fee/fee.service";
import { SimulationService } from "./services/external/simulation/simulation.service";
import { DialogService } from "./services/internal/dialog/dialog.service";
import { DataStoreService } from "./store/data-store.service";
import { getValueWithDecimalPlaces } from "./utils/decimal-places";
import { isCPF } from "./utils/cpf-util";
import { RateDataDTO } from "./components/simulation-economical-group/models/economical-group-simulation.model";
import { TechEventDTO } from "./components/simulation-data/fees-data/models/technology-information-dto";
import { SimulatorConfigService } from "./services/external/simulator-config/simulator-config.service";
import { sanitizeCpfCnpj } from "./utils/sanitize-search-filter";

@Component({
  selector: "app-order",
  templateUrl: "./simulation.component.html",
  styleUrls: ["./simulation.component.scss"],
})
export class SimulationComponent implements OnInit {
  public readonly ID_RANGE = "1";
  public readonly RECEIVING_DEADLINE = 2;
  public readonly CEF_INSTITUTION = "00000007"
  public readonly CEF_SERVICE_CONTRACT = "149";

  negotiationForm = false;
  createGroupForm = false;
  progressiveDiscountTable = false;

  formData: any;
  public proposal: SimulationModel = {
    clientInformation: new ClientInformationModel(),
  };
  public offerQueryData = { campaignId: 0 } as OfferQueryDataModel;

  private genericMdrFeeSubject = new BehaviorSubject<RateDataDTO[]>(
    null
  );
  private factorFeesSubject = new BehaviorSubject<FactorFeeModel[]>(null);
  private advanceFeeSubject = new BehaviorSubject<AdvanceFeeModel[]>(null);
  private simulationInformation = new SimulationInformationModel();
  private campaignSimulation = new CampaignSimulationModel();
  private techReportDtoList : TechReportDTO[] = new Array<TechReportDTO>();;

  genericFeesProperties: TableColumnsModel[] = [
    { name: "flag", columnTitle: "Bandeira", type: "string" },
    { name: "transaction", columnTitle: "Transação", type: "string" },
    { name: "suggestedMdrFee", columnTitle: "Taxa", type: "fee" },
  ];

  feesPropertiesLotericas: TableColumnsModel[];

  factorFeesProperties: TableColumnsModel[] = [
    { name: "flag", columnTitle: "Bandeira", type: "string" },
    { name: "transaction", columnTitle: "Transação", type: "string" },
    { name: "suggestedFactorFee", columnTitle: "Taxa", type: "fee" },
  ];

  advanceFeeProperties: TableColumnsModel[] = [
    { name: "annualBilling", columnTitle: "Faturamento/Ano", type: "string" },
    { name: "suggestedFee", columnTitle: "Taxa/Mês", type: "fee" },
  ];

  public viewControl = {
    clientInformation: true,
    feesData: false,
    factorTableData: false,
    advanceFeeTableData: false,
    offerTableData: false,
    offerTableDataLoterica: false,
    offerTableDataLotericaPinpad: false,
  };

  hasPercentageDebitCampaing24: boolean = false;

  enableSimulatorAudit: boolean;

  constructor(
    private feeService: FeeService,
    private simulationService: SimulationService,
    private dataStoreService: DataStoreService,
    private router: ActivatedRoute,
    private route: Router,
    private dialogService: DialogService,
    private cnaeService: CnaeService,
    private dataStorage: DataStoreService,
    private simulatorConfigService: SimulatorConfigService
  ) {}

  ngOnInit(): void {
    let queryParams = {};
    let decript = true;
    this.isEnableAudit();
    if (
      this.router.snapshot.url.length <= 0 ||
      this.router.snapshot.url[0].path !== "simulation" ||
      this.router.snapshot.url[1].path !== "client-information"
    ) {
      if (decript && this.router.snapshot.queryParams["key"]) {
        this.simulationService
          .decriptParam(this.router.snapshot.queryParams["key"])
          .toPromise()
          .then((response) => {
            queryParams = response;
            this.validateParams(queryParams);
            this.route.navigate(["/simulation/client-information"]);
          })
          .catch((error) => {
            this.dialogService.openDialog(Messages.FAIL_TO_DECRYPT);
          });
      } else {
        this.route.navigateByUrl("/simulation/client-information");
      }
    } else {
      this.route.navigateByUrl("/simulation/client-information");
    }


  }

   async isEnableAudit(){
    this.enableSimulatorAudit = true;
  }

  public insertTariffColumn() {
    if(!this.hasPercentageDebitCampaing24) {
      this.feesPropertiesLotericas = [
        { name: "flag", columnTitle: "Bandeira", type: "string" },
        { name: "transaction", columnTitle: "Transação", type: "string" },
        { name: "suggestedMdrFee", columnTitle: "Taxa", type: "fee" },
        { name: "suggestedMdrFeeTariff", columnTitle: "Tarifa", type: "currencyOrTax",}
      ]
    } else {
      this.feesPropertiesLotericas = [
        { name: "flag", columnTitle: "Bandeira", type: "string" },
        { name: "transaction", columnTitle: "Transação", type: "string" },
        { name: "suggestedMdrFee", columnTitle: "Taxa", type: "fee" }
      ];
    }
  }


  validateParams(queryParams) {
    const cpfCnpj = queryParams["cnpj"];
    const state = queryParams["uf"];
    const cnaeParam = this.stripLeadingZeros(queryParams["cnae"]);
    const registrationNumber = queryParams["matricula"];

    if (!cpfCnpj || !state || !cnaeParam || !registrationNumber) {
      this.dialogService.openDialog(Messages.FAIL_TO_DECRYPT);
      return;
    }

    this.cnaeService
      .checkCnae(cnaeParam, this.CEF_SERVICE_CONTRACT)
      .toPromise()
      .then((data) => {
        if (!data) {
          this.dialogService.openDialogWithHtmlMessage(Messages.UNWANTED_CNAE);
        }
      });

    queryParams["cnae"] = cnaeParam;
    this.dataStoreService.setParam(queryParams);
  }

  stripLeadingZeros(cnae) {
    return cnae.replace(/^0*/g, "");
  }

  private param(op: string): ParamRates {
    let param: ParamRates = {
      personType: isCPF(this.proposal.clientInformation.cpfCnpj) ? "P" : "L",
      cnae: this.proposal.clientInformation.cnaeNumber.toString(),
      annualBillingValue: Number(this.proposal.clientInformation.billingValue),
      idCampaign: this.offerQueryData.campaignId,
      campaignCode: String(this.offerQueryData.campaignCode),
      uf: this.proposal.clientInformation.state,
      receivingDeadline: Number(this.RECEIVING_DEADLINE),
      chargeModelEnum: op,
      cpfCnpj: this.proposal.clientInformation.cpfCnpj,
      automaticPrepayment: this.offerQueryData.isPrepaymentEnabled,
      offerMessage: this.offerQueryData.getOfferMessage(),
      hasEconomicGroup: this.offerQueryData.hasEconomicGroup,
      institutionId: this.CEF_INSTITUTION,
      serviceContract: Number(this.CEF_SERVICE_CONTRACT),
      campaignName: String(this.offerQueryData.campaignName),
      techReport: this.techReportDtoList
    };
    return param;
  }

  public clear() {
    // Desabilitando views
    this.viewControl.feesData = false;
    this.viewControl.advanceFeeTableData = false;
    this.viewControl.offerTableData = false;
    this.viewControl.factorTableData = false;
    this.viewControl.offerTableDataLoterica = false;
    this.viewControl.offerTableDataLotericaPinpad = false;
    // Zerando estado dos componentes
    this.proposal.clientInformation = null;
    this.offerQueryData = { campaignId: 0 } as OfferQueryDataModel;
    this.genericMdrFeeSubject.next(null);
    this.advanceFeeSubject.next(null);
    this.factorFeesSubject.next(null);
  }

  // Função que dispara a pesquisa por campanhas no componente FeesData
  public showFeesData(clientInformation) {
    this.proposal.clientInformation = clientInformation;
    this.viewControl.feesData = true;
  }

  // Função que dispara as chamadas para popular as tabelas de taxas
  public showOfferData(offerQueryData: OfferQueryDataModel) {
    this.offerQueryData = offerQueryData;
    let cnae = this.proposal.clientInformation.cnaeNumber;
    let campaignCode = offerQueryData.campaignCode;
    this.clearSimulationCodAndCampaignFees();

    if (offerQueryData.feeType === FeeTypeEnum.MDR) {
      if (this.isLottery(cnae, campaignCode)) {
        this.getMdrFeesWithTariff();
      } else {
        this.getMdrFees();
      }
      this.factorFeesSubject.next(null);
    } else if (offerQueryData.feeType === FeeTypeEnum.FLEX) {
      this.viewControl.offerTableDataLotericaPinpad = false;

      if(this.isCnaeLottery(cnae) &&  this.isCampaignCodeLotteryPinpad(campaignCode) ){
        this.viewControl.offerTableDataLotericaPinpad  = true;
      }

      if (this.isLottery(cnae, campaignCode)) {
        this.getMdrFlexFeesWithTariff();
      } else {
        this.getMdrFlexFees();
      }
    }
  }


  public isLottery(cnaeNumber: number, campaignCode: number) {
    return (
      this.isCnaeLottery(cnaeNumber) && this.isCampaignCodeLottery(campaignCode)
    );
  }

  public isCnaeLottery(cnaeNumber) {
    return cnaeNumber === Constants.LOTTERY_CNAE;
  }

  public isCampaignCodeLottery(campaignCode: number) {
    return campaignCode === Constants.LOTTERY_CAMPANHA_CODE;
  }

  public isCampaignCodeLotteryPinpad(campaignCode: number) {
    return campaignCode === Constants.LOTTERY_PINPAD_CAMPANHA_CODE;
  }

  public getMdrFeesWithTariff() {
    let param = this.param("MDR");
    this.viewControl.factorTableData = false;
    this.viewControl.offerTableData = false;
    this.viewControl.advanceFeeTableData = false;
    this.viewControl.offerTableDataLoterica = false;
    this.viewControl.offerTableDataLotericaPinpad = false;


    combineLatest([
      this.feeService.getMdrFees(param),
      this.feeService.getPhrases(param),
      this.simulationService.getPercentageCampaign24()
    ]).subscribe(
      ([mdrFees, phrases, hasPercentageDebitCampaing24]) => {
        this.hasPercentageDebitCampaing24 = hasPercentageDebitCampaing24;
        this.insertTariffColumn();
        this.viewControl.offerTableData = true;
        this.viewControl.offerTableDataLoterica = true;
        this.genericMdrFeeSubject.next(mdrFees.ratesByFlag);
        this.offerQueryData.phrases = phrases;
        this.buildSimulationMdrRates(GenericMdrFeeModel.arrayMap(mdrFees.ratesByTransaction, this.hasPercentageDebitCampaing24));

        if (this.offerQueryData.isPrepaymentEnabled) {
          this.getPrepaymentFees(param);
        }
      },
      () => {
        this.viewControl.advanceFeeTableData = false;
        this.viewControl.offerTableData = false;
      }
    );
  }

  public getMdrFlexFeesWithTariff() {
    let param = this.param("MDR FLEX");
    this.viewControl.offerTableData = false;
    this.viewControl.advanceFeeTableData = false;
    this.viewControl.offerTableDataLoterica = false;

    combineLatest([
      this.feeService.getMdrFlexFees(param),
      this.feeService.getFactorFees(param),
      this.feeService.getPhrases(param),
      this.simulationService.getPercentageCampaign24()
    ]).subscribe(
      ([mdrFlexFees, factorFees, phrases, hasPercentageDebitCampaing24]) => {
        this.hasPercentageDebitCampaing24 = hasPercentageDebitCampaing24;
        this.insertTariffColumn();
        this.viewControl.offerTableData = true;
        this.viewControl.offerTableDataLoterica = true;
        this.genericMdrFeeSubject.next(mdrFlexFees.ratesByFlag);

        this.viewControl.factorTableData = true;
        this.factorFeesSubject.next(factorFees);
        this.buildSimulationFactorFees(factorFees);
        this.buildSimulationMdrRates(GenericMdrFeeModel.arrayMap(mdrFlexFees.ratesByTransaction, this.hasPercentageDebitCampaing24));

        this.offerQueryData.phrases = phrases;
      },
      () => {
        this.viewControl.factorTableData = false;
        this.viewControl.advanceFeeTableData = false;
        this.viewControl.offerTableData = false;
      }
    );
  }

  public getMdrFlexFees() {
    let param = this.param("MDR FLEX");
    this.viewControl.advanceFeeTableData = false;
    this.viewControl.offerTableDataLoterica = false;

    combineLatest([
      this.feeService.getMdrFlexFees(param),
      this.feeService.getFactorFees(param),
      this.feeService.getPhrases(param),
      this.simulationService.getPercentageCampaign24()
    ]).subscribe(
      ([mdrFlexFees, factorFees, phrases, hasPercentageDebitCampaing24]) => {
        this.hasPercentageDebitCampaing24 = hasPercentageDebitCampaing24;
        this.insertTariffColumn();
        this.viewControl.offerTableData = true;
        this.genericMdrFeeSubject.next(mdrFlexFees.ratesByFlag);
        this.viewControl.factorTableData = true;
        this.factorFeesSubject.next(factorFees);
        this.buildSimulationMdrRates(GenericMdrFeeModel.arrayMap(mdrFlexFees.ratesByTransaction, this.hasPercentageDebitCampaing24));
        this.buildSimulationFactorFees(factorFees);

        this.offerQueryData.phrases = phrases;
      },
      () => {
        this.viewControl.factorTableData = false;
        this.viewControl.advanceFeeTableData = false;
        this.viewControl.offerTableData = false;
      }
    );
  }

  public getMdrFees() {
    let param = this.param("MDR");
    this.viewControl.factorTableData = false;
    this.viewControl.advanceFeeTableData = false;
    this.viewControl.offerTableDataLoterica = false;

    combineLatest([
      this.feeService.getMdrFees(param),
      this.feeService.getPhrases(param),
      this.simulationService.getPercentageCampaign24()
    ]).subscribe(
      ([mdrFees, phrases, hasPercentageDebitCampaing24]) => {
        this.hasPercentageDebitCampaing24 = hasPercentageDebitCampaing24;
        this.insertTariffColumn();
        this.viewControl.offerTableData = true;
        this.genericMdrFeeSubject.next(mdrFees.ratesByFlag);
        this.buildSimulationMdrRates(GenericMdrFeeModel.arrayMap(mdrFees.ratesByTransaction, this.hasPercentageDebitCampaing24));

        this.offerQueryData.phrases = phrases;

        if (this.offerQueryData.isPrepaymentEnabled) {
          this.getPrepaymentFees(param);
        }
      },
      () => {
        this.viewControl.advanceFeeTableData = false;
        this.viewControl.offerTableData = false;
      }
    );
  }

  private getPrepaymentFees(param) {
    this.feeService.getPrepaymentFees(param).subscribe((response) => {
      this.viewControl.advanceFeeTableData = true;
      this.advanceFeeSubject.next([
        {
          annualBilling:
            this.offerQueryData.billingRangeStart +
            " - " +
            this.offerQueryData.billingRangeEnd,
          suggestedFee: getValueWithDecimalPlaces(
            response.suggestedAutoPrepayment
          ),
        },
      ]);
      this.campaignSimulation.suggestedAutoPrepayment = response.suggestedAutoPrepayment;
    });
  }

  // ---- Funções utilizadas para disparo e controle de dados do front ----
  get factorFees$() {
    return this.factorFeesSubject.asObservable();
  }

  get advanceFee$() {
    return this.advanceFeeSubject.asObservable();
  }

  get genericMdrFee$() {
    return this.genericMdrFeeSubject.asObservable();
  }

  public enableAnalysisButton() {
    const revenueRangeValue = Number(
      this.proposal.clientInformation.billingValue
    );
    if (revenueRangeValue >= 360000.01 && revenueRangeValue <= 999999999.99) {
      return true;
    } else {
      return false;
    }
  }

  public enabledCampaign() {
    if (
      this.offerQueryData.campaignCode >= 28 &&
      this.offerQueryData.campaignCode <= 32
    ) {
      return false;
    } else {
      return true;
    }
  }

  public reloadPage() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.route.navigateByUrl(currentUrl);
    });
  }

  public printOffer() {
    if(this.simulationInformation.simulationTechnologyDTO.technologyAmount == null || this.simulationInformation.simulationTechnologyDTO.technologyName == null){
      this.dialogService.openDialog(Messages.INVALID_SIMULATION_TECHS);
      return;
    }

    let typeOp = this.offerQueryData.feeType as string;
    this.feeService.getReport(this.param(typeOp))
      .subscribe((response) => {
        this.processReportResponse(response);
      },
    () => this.dialogService.openDialog(Messages.FAILED_TO_GET_REPORT));
  }

  private processReportResponse(response) {
    if (response.type === HttpEventType.Response) {
      const blob = new Blob([response.body], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      let newWin = window.open(url);
      if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
        this.processDownload(url);
      }
    }
  }

  private processDownload(url: any) {
    let link = document.createElement("a");
    link.href = url;
    link.download = "report.pdf";
    link.innerHTML = "click";
    link.click();
    window.URL.revokeObjectURL(url);
  }

  public openNegotiationForm() {
    this.negotiationForm = true;
    window.scroll(0, 0);
  }

  public openSideNav(event) {
    this.createGroupForm = event === "economical-group-conv";
    this.progressiveDiscountTable = event === "progressive-discount-table";
    this.negotiationForm = true;
    window.scroll(0, 0);
  }

  goBack() {
    this.createGroupForm = false;
    this.negotiationForm = false;
    window.scroll(0, 0);
  }

  public finishSimulation() {
    if (!this.simulationInformation.simulationCod) {
      this.buildSimulationData();
      if(this.simulationInformation.simulationTechnologyDTO.technologyAmount == null || this.simulationInformation.simulationTechnologyDTO.technologyName == null){
        this.dialogService.openDialog(Messages.INVALID_SIMULATION_TECHS);
        return;
      }

      this.simulationService.saveSimulationInformation(this.simulationInformation)
      .subscribe(response => {
        if (response) this.simulationInformation.simulationCod = response.data;
        this.goToFinishSimulation();
      },
    () => {
      this.dialogService.openDialog(Messages.FAILED_TO_SAVE_SIMULATION);
      });
    } else {
      this.goToFinishSimulation();
    }
  }

  private goToFinishSimulation() {
    this.route.navigateByUrl("/simulation/finish");
  }

  public closeNegotiationForm() {
    this.negotiationForm = false;
    window.scroll(0, 0);
  }

  public showProgressiveDiscount() {
    this.progressiveDiscountTable = true;
  }

  public closeProgressiveDiscount(close: boolean) {
    this.progressiveDiscountTable = false;
    this.negotiationForm = false;
  }

  private buildSimulationData() {
    this.simulationInformation.managerCod = this.dataStorage.getRegisterUserCode();
    this.simulationInformation.annualBilling = this.proposal.clientInformation.billingValue;
    this.simulationInformation.cnae = this.proposal.clientInformation.cnaeNumber;
    this.simulationInformation.cpfCnpj = sanitizeCpfCnpj(this.proposal.clientInformation.cpfCnpj);
    this.simulationInformation.uf = this.proposal.clientInformation.state;
    this.simulationInformation.institution = this.CEF_INSTITUTION;
    this.simulationInformation.serviceContract = Number(this.CEF_SERVICE_CONTRACT);
    this.campaignSimulation.campaignCod = this.offerQueryData.campaignId;
    this.simulationInformation.campaign = this.campaignSimulation;
    this.simulationInformation.level = 1;
    let phrases = this.offerQueryData.phrases;
    if (phrases) {
      this.simulationInformation.simulationPhrase.exemptionPolicyPhrase = phrases.phrase;
      this.simulationInformation.simulationPhrase.pricingTechnologyPhrase = phrases.pricingTechnologyPhrase;
      this.simulationInformation.simulationPhrase.disclaimerPhrase = phrases.disclaimerPhrase;
      this.simulationInformation.simulationPhrase.disclaimerTechPhrase = phrases.disclaimerTechPhrase;
      this.simulationInformation.simulationPhrase.offerMessage = phrases.offerMessage;
      this.simulationInformation.simulationPhrase.exemptionPolicyPhraseFactorFlex = phrases.exemptionPolicyPhraseFactorFlex;
    }
  }

  private buildSimulationFactorFees(factorFees: FactorFeeModel[]) {
    if (factorFees && factorFees.length > 0) {
      this.campaignSimulation.suggestedFactorFee1 = factorFees.filter(factor => factor.transaction === "Fator 1")[0].suggestedFactorFee;
      this.campaignSimulation.suggestedFactorFee2 = factorFees.filter(factor => factor.transaction === "Fator 2")[0].suggestedFactorFee;
    }
  }

  private clearSimulationCodAndCampaignFees() {
    this.simulationInformation.simulationCod = null;
    this.campaignSimulation.suggestedAutoPrepayment = null;
    this.campaignSimulation.suggestedFactorFee1 = null;
    this.campaignSimulation.suggestedFactorFee2 = null;
  }

  private buildSimulationMdrRates(mdrRates: GenericMdrFeeModel[]) {
    this.simulationInformation.mdrRates = mdrRates;
  }

  public buildTechs(techEventDTO: TechEventDTO) {
    this.simulationInformation.simulationTechnologyDTO.technologyAmount = techEventDTO.techInfo.amount;
    this.simulationInformation.simulationTechnologyDTO.technologyName = techEventDTO.techInfo.tradeName;
    this.simulationInformation.simulationTechnologyDTO.technologyRent = techEventDTO.techInfo.suggestedTechPrice;
    this.simulationInformation.simulationTechnologyDTO.serviceId = techEventDTO.techInfo.serviceId;

    let techReportObject = new TechReportDTO();
    techReportObject.name = techEventDTO.techTableInfo.tradeName;
    techReportObject .totalValue = techEventDTO.techTableInfo.totalValue;
    techReportObject.value = techEventDTO.techTableInfo.suggestedTechPrice;
    techReportObject.qtd = techEventDTO.techInfo.amount;

    this.techReportDtoList[0] = techReportObject;
  }

  public getSimulationInformation() {
    this.buildSimulationData();
    return this.simulationInformation;
  }
}
