import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function CnaeValidateByMCC(groupPrice): ValidatorFn {
 
    return (control: AbstractControl): ValidationErrors | null => {
        let cnae = control.value;
        let cnaeList = groupPrice.cnaeListByMcc;
        
        if (cnae && cnaeList.length > 0) {
            if (!cnaeList.some(e => e.cnaeNumber === cnae)) {
                return { invalidCnae: { value: control.value } };
            }
        }
        return {};
    }
   
}