import { Component, Inject, OnInit, ViewChild} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EconomicGroupService } from "src/app/order/services/external/economic-group/economic-group.service";
import { LoadingService } from "src/app/order/services/internal/loading/loading.service";
import { finalize } from "rxjs/operators";
import { DialogService } from "src/app/order/services/internal/dialog/dialog.service";
import { Messages } from "src/app/order/messages/order.messages";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { EconomicalGroupSimulationDetailModel } from "../../simulation-economical-group/models/economical-group-simulation.model";

@Component({
    selector: 'app-simulation-economical-group-detail',
    templateUrl: './simulation-economical-group-detail.component.html',
    styleUrls: ['./simulation-economical-group-detail.component.scss'],
  })
export class SimulationEconomicalGroupDetail implements OnInit {

  dataSource: MatTableDataSource<EconomicalGroupSimulationDetailModel>;
  displayedColumns = ['CNPJ', 'Status do CNPJ', 'Orientação'];
  sizeItems = 5;
  pageNumber = 0;
  length = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public simulationCod: any,
    private EconomicGroupService: EconomicGroupService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<EconomicalGroupSimulationDetailModel>,) {
    
  }

  ngOnInit() {
    this.getEconomicGroupSimulationDetails();
  }

  getEconomicGroupSimulationDetails() {
    this.EconomicGroupService.getEconomicGroupSimulationDetail(this.simulationCod, this.sizeItems, this.pageNumber)
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(item => {
      this.dataSource = new MatTableDataSource(item.response);
      this.length = item.totalItens;
      this.sizeItems = item.size;
      this.dataSource.paginator = this.paginator;
    }, () => {
      this.dialogService.openDialog(Messages.ECONOMIC_GROUP_SIMULATION_DETAILS_ERROR);
      this.close();
    })
  }

  get hasValue() {
    return this.dataSource && this.dataSource.data.length > 0;
  }

  close() {
    this.dialogRef.close();
  }

  changePage(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.sizeItems = event.pageSize;
    this.getEconomicGroupSimulationDetails();
  }
  
}